import React, { useRef } from "react";
import LabelContainer from "../LabelContainer";
import { DateTime } from "luxon";
import EmptyButton from "./EmptyButton";

const DateInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  min = "",
  max = "",
  ...props
}) => {
  const inputRef = useRef(null);
  const valueChange = (e) => {
    var filteredValue = DateTime.fromISO(e.target.value).toISO();
    console.log(filteredValue);
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  const previousDate = () => {
    var prevDate = DateTime.fromISO(value).minus({ days: 1 }).toISO();
    valueChange({
      target: {
        value: prevDate,
      },
    });
  };

  const nextDate = () => {
    var nextDate = DateTime.fromISO(value).plus({ days: 1 }).toISO();
    valueChange({
      target: {
        value: nextDate,
      },
    });
  };

  return (
    <LabelContainer {...props} value={value}>
      {props.withNav ? (
        <button
          className="btn bg-white btn-sm mr-2 noprint"
          onClick={previousDate}
        >
          <i className="fa fa-arrow-left" />
        </button>
      ) : null}
      <div className="d-center mr-2">
        {value ? DateTime.fromISO(value).setLocale("FR").toFormat("EEEE") : ""}
      </div>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        style={inputStyle}
        min={min}
        max={max}
        className={"form-control " + inputClassName}
        type="date"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={DateTime.fromISO(value).toFormat("yyyy-MM-dd")}
        onChange={valueChange}
        placeholder={placeholder}
      />
      <EmptyButton
        change={change}
        name={props.name}
        targetInput={inputRef}
        value={value}
        right={34}
      />
      {props.withNav && (
        <button className="btn bg-white btn-sm ml-2 noprint" onClick={nextDate}>
          <i className="fa fa-arrow-right" />
        </button>
      )}
    </LabelContainer>
  );
};

export default DateInput;
