import React from "react";
import { APP_NAME } from "../../../config";

const RegisterSuccess = () => {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column mx-auto mt-5 login-form text-secondary animated zoomIn">
      <div className=" text-center">
        <div className="mb-5">
          <img src="/images/logo.svg" />
        </div>
        <h1 className="my-5  text-secondary main-title">
          Bienvenue sur {APP_NAME} !
          <hr className="w-50 mt-4" />
        </h1>
      </div>
      <div className="w-75 m-auto">
        <p>Votre compte a été créé avec succès.</p>
        <p>
          Un email d'activation vous a été envoyé, veuillez cliquer sur le lien
          qu'il contient.
        </p>
      </div>
    </div>
  );
};

export default RegisterSuccess;
