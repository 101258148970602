import React, { useState } from "react";
import PrintTechnicalSheetStandalone from "../../../../TechnicalSheets/PrintTechnicalSheetStandalone/PrintTechnicalSheetStandalone";

const DishItem = ({ sl, dlTSLocked, setDlTSLocked }) => {
  const [showTS, setShowTS] = useState();

  return (
    <>
      <div
        className="px-2 py-1 d-flex flex-fill justify-content-start align-items-center dish-caret-item"
        title={
          sl.technicalSheet.name + " - Cliquez pour voir la fiche technique"
        }
        onClick={() => {
          if (!dlTSLocked) setShowTS((s) => !s);
        }}
        style={{
          background: sl.color,
          fontSize: 12,
          color: "white",
          wordBreak: "break-word",
          lineHeight: "14px",
          fontWeight: "600",
          cursor: "pointer",
          position: "relative",
        }}
      >
        {sl.technicalSheet.name}
        {showTS && (
          <PrintTechnicalSheetStandalone
            technicalSheetId={sl.technicalSheet.id}
            setIsLoading={setDlTSLocked}
          />
        )}
      </div>
    </>
  );
};

export default DishItem;
