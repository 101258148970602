import React from "react";
import ClientSelector from "./ClientSelector";
import PeriodSelector from "./PeriodSelector";
import ProductionSiteSelector from "./ProductionSiteSelector";
import SiteSelector from "./SiteSelector";
import TimeScaleSelector from "./TimeScaleSelector";

const Filters = ({ search, setSearch }) => {
  return (
    <>
      <div className="custom-card">
        <div className="row">
          <div className="col-12 col-md-4">
            <SiteSelector
              productionSite={search.productionSite}
              client={search.client}
              site={search.site}
              setSite={(e) => setSearch({ ...search, site: e })}
            />
          </div>
        </div>
        <div className="row">
          <PeriodSelector
            beginAt={search.beginAt}
            endAt={search.endAt}
            setBeginAt={(e) => setSearch({ ...search, beginAt: e })}
            setEndAt={(e) => setSearch({ ...search, endAt: e })}
          />
          <div className="col-4">
            <TimeScaleSelector
              timeScale={search.timeScale}
              setTimeScale={(e) => setSearch({ ...search, timeScale: e })}
            />
          </div>
        </div>

        <button
          className="btn btn-default w-100 mt-3"
          onClick={() => {
            document.querySelectorAll(".load-stat").forEach((elem) => elem.click());
          }}
        >
          Charger toutes les stats <i className={`ml-2 fa fa-refresh `} />
        </button>
      </div>
    </>
  );
};

export default Filters;
