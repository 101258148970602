import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";

const Logout = () => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);

  useEffect(() => {
    actions.logoutUser();
  }, []);

  useEffect(() => {
    if (!state.auth.isAuthenticated) {
      window.location.href = "/";
    }
  }, [state.auth]);

  return <></>;
};

export default Logout;
