import React from "react";
import useStore from "../../../../../context/useStore";
import Control from "../../../../common/Controls/Control";

const TimeScaleSelector = ({ timeScale, setTimeScale }) => {
  const [state, dispatch] = useStore();

  const constants = state.constants.items;
  const { TIME_SCALES } = constants;

  return (
    <Control
      label="Echelle de temps"
      type="btnList"
      btnInline
      value={timeScale}
      change={(e) => setTimeScale(e.target.value)}
      datas={TIME_SCALES}
      dataIndex="id"
      dataLabel="name"
      inputGroupStyle={{ paddingLeft: 0 }}
    />
  );
};

export default TimeScaleSelector;
