import React, { useRef } from "react";

const ButtonItem = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  data,
  dk,
  dataIndex = "id",
  dataLabel = "name",
  dataLabelIndex = false, //si le label est dans un objet ex : { label: { name: "Label"} }
  dataLabel2 = false,
  dataIcon = false,
  btnInline = false,
  itemClassName = "",
  itemStyle = {},
  isActive,
  valueChange,
  name,
}) => {
  const btnRef = useRef(null);

  return (
    <button
      ref={btnRef}
      className={`
            btn  
            position-relative
            flex-row align-items-center flex-fill justify-content-between mb-0 
            ${itemClassName} 
            ${
              btnInline
                ? "btn-sm text-center my-1 mx-1 flex-column justify-content-center flex-wrap "
                : " "
            }
              ${
                isActive
                  ? btnInline
                    ? "btn-primary "
                    : "btn-default active  "
                  : "btn-default"
              }
            `}
      style={{
        ...itemStyle,
      }}
      disabled={disabled ? "disabled" : false}
      type="button"
      key={`${name} - ${
        data.id ? data.id : data._id ? data._id + dk : "abcd" + dk
      } `}
      onClick={(e) => {
        valueChange(e, data[dataIndex], data.isExclusive);
      }}
    >
      {dataIcon && (
        <div
          className={`d-flex align-items-center justify-content-center p-2 ${
            !btnInline ? "mr-2" : ""
          }`}
        >
          <i
            className={"illustration fa fa-" + data[dataIcon] + ""}
            style={{
              background: "transparent",
              fontSize: 26,
              padding: 0,
              width: 26,
            }}
          />
        </div>
      )}
      <p className={`d-flex flex-column mb-0 font-weight-bold`}>
        {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}

        {data.dataLabel2 && (
          <span
            className={`mt-1 `}
            style={{ fontSize: 11, lineHeight: "12px" }}
          >
            {data.dataLabel2}
          </span>
        )}
      </p>

      <i
        className={`check-icon fa fa-check ml-2 animated ${
          isActive ? "fadeInRight" : "fadeOutRight"
        } faster 
          ${
            btnInline
              ? "position-absolute text-primary bg-white shadow-sm border"
              : ""
          }
                  `}
        style={
          btnInline
            ? {
                top: -7,
                right: -7,
                fontSize: 10,
                padding: "3px",
                borderRadius: "100%",
              }
            : {}
        }
      />
    </button>
  );
};

export default ButtonItem;
