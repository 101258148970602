import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";

const sitesAtom = atom([]);

const useSites = (fetchOnLoad = true) => {
  const [sites, setSites] = useAtom(sitesAtom);

  useEffect(() => {
    if (fetchOnLoad) {
      getSites();
    }
  }, []);

  const getSites = () => {
    dataService.get(`sites/mines`, setSites);
  };

  return {
    sites,
    setSites,
    getSites,
  };
};

export default useSites;
