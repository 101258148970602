import React from "react";

const ContactBlock = ({
  text = "Pour ajouter un modifier un profil de consommation contactez Qualicarry.",
}) => {
  return (
    <div className="alert alert-warning rounded text-center">
      {text}
      <div className="d-flex align-items-center justify-content-around flex-wrap">
        <a href="tel:0262251805" className="btn btn-link">
          0262 25 18 05
        </a>
        <a href="mailto:cuisinecentrale@qualicarry.fr" className="btn btn-link">
          cuisinecentrale@qualicarry.fr
        </a>
      </div>
    </div>
  );
};

export default ContactBlock;
