
export const modalActions = (state, dispatch, props) => {
  function updateModal(modalData) {
    dispatch({ type: "MODAL_UPDATE", payload: modalData });
  }

  return {
    updateModal,
  };
};
