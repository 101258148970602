import React, { useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import logo from "./logo.svg";
import PrivateRoute from "./components/common/PrivateRoute";
import tools from "./helpers/tools";
import jwt_decode from "jwt-decode";
// Pages
import useStore from "./context/useStore";
import { authActions } from "./context/actions/authActions";

import Sidenav from "./components/common/layout/Sidenav";
import Login from "./components/pages/Login/Login";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { constantActions } from "./context/actions/constantActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import Colors from "./theme/variables.scss";
import { loadReCaptcha } from "react-recaptcha-v3";
import { recaptcha_token, API_URL } from "./config";
import Axios from "axios";
import Register from "./components/pages/Register/Register";
import Profile from "./components/pages/Profile/Profile";
import CustomModal from "./components/common/CustomModal/CustomModal";
import SidePanel from "./components/common/SidePanel/SidePanel";
import Test from "./components/pages/Test";
import Logout from "./components/pages/Logout/Logout";
import { AnimatePresence } from "framer-motion";
import BaseList from "./components/pages/BaseList/BaseList";
import Loader from "./components/common/Loader";
import ClientDetail from "./components/pages/Clients/ClientDetail/ClientDetail";
import Order from "./components/pages/Order/Order";
import RoleStyle from "./components/common/layout/RoleStyle";
import ClientMenuPrint from "./components/pages/Order/OrderPage/ClientMenuPrint";
import BillingSummary from "./components/pages/Summary/Billing/BillingSummary";
import TechnicalSheetList from "./components/pages/TechnicalSheets/TechnicalSheetList/TechnicalSheetList";

window.borderRadius = Colors.borderRadius;

var firstLoad = true;
const App = () => {
  const [state, dispatch] = useStore();
  console.log("state", state);
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  Axios.defaults.withCredentials = true;
  Axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== API_URL + "auth/logout") {
          actions.logoutUser();
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  if (localStorage.jwtToken && firstLoad) {
    firstLoad = false;
    // Set auth token header auth
    //tools.setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    // Set user and isAuthenticated
    dispatch({
      type: "SET_CURRENT_USER",
      payload: decoded,
    });

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      actions.logoutUser();
      // Clear current Profile
      // store.dispatch(clearCurrentProfile())
      // Redirect to login
    }
  }

  useEffect(() => {
    if (!localStorage.jwtToken) {
      Axios.get(API_URL + "auth/me")
        .then((res) => {
          const decoded = jwt_decode(res.data);
          dispatch({
            type: "SET_CURRENT_USER",
            payload: decoded,
          });
        })
        .catch((err) => {
          actions.logoutUser();
        });
    }
    c_actions.getConstants();
    loadReCaptcha(recaptcha_token, () => {
      console.log("captcha loaded !");
    });
  }, []);

  const sidenavItems = [
    {
      name: "Accueil",
      path: "/dashboard",
      icon: "home",
    },
    {
      name: "Commander",
      path: "/order",
      icon: "home",
      dataPriv: "order",
    },
    {
      name: "Compte client",
      path: "/client",
      icon: "home",
      dataPriv: "manage_account",
    },
    {
      name: "Récapitulatif d'activité",
      path: "/recap-activite",
      icon: "file",
    },
    {
      name: "Fiches techniques",
      path: "/fiches-techniques",
      icon: "file",
    },
  ];

  return (
    <>
      <Router>
        <div className="wrapper">
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
          <Sidenav sidenavItems={sidenavItems} />
          <div id="content" className="position-relative">
            {/* Same as */}
            {Object.keys(state.constants.items).length ? (
              <div id="content" className="position-relative">
                <AnimatePresence exitBeforeEnter={true}>
                  <Switch>
                    <Route path="/list" exact component={BaseList} />
                    <Route
                      path="/renew-password/:token"
                      exact
                      component={RenewPassword}
                    />

                    {process.env.NODE_ENV == "development" && (
                      <Route path="/test" exact component={Test} />
                    )}
                    <Route
                      path="/activate-account/:token/:email"
                      exact
                      component={ActivateAccount}
                    />
                    <Route
                      path="/renew-password/:token/:email"
                      exact
                      component={RenewPassword}
                    />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/register" exact component={Register} />

                    <PrivateRoute path="/" exact component={Dashboard} />
                    <PrivateRoute
                      path="/dashboard"
                      exact
                      component={Dashboard}
                    />
                    <PrivateRoute
                      path="/client"
                      exact
                      component={ClientDetail}
                    />
                    <PrivateRoute path="/order" exact component={Order} />
                    <PrivateRoute
                      path="/orders/print/:menurefId/:date/:clientId/:siteId"
                      exact
                      component={ClientMenuPrint}
                    />
                    <PrivateRoute path="/profile" exact component={Profile} />
                    <PrivateRoute
                      path="/recap-activite"
                      exact
                      component={BillingSummary}
                    />
                    <PrivateRoute
                      path="/fiches-techniques"
                      exact
                      component={TechnicalSheetList}
                    />
                  </Switch>
                </AnimatePresence>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </Router>
      <CustomModal />
      <SidePanel />
      <RoleStyle />
    </>
  );
};

export default App;
