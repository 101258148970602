import React, { useContext, useEffect } from "react";
import Page from "../../../common/layout/Page";
import { itemsActions } from "./Context/actions/ItemsActions";
import useItemStore from "./Context/useItemStore";

const ItemsPage = (props) => {
  const [state, dispatch] = useItemStore();
  const item_actions = itemsActions(state, dispatch);

  useEffect(() => {
    item_actions.initItems(props.items);
  }, []);

  return (
    <Page title={props.pageTitle} isLoading={state.isLoading} back={props.back}>
      {state.isInit ? props.children : null}
    </Page>
  );
};

export default ItemsPage;
