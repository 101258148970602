import React from "react";
import LabelContainer from "../LabelContainer";
import ButtonItem from "./ButtonItem";

const CheckBtnListInput = (props) => {
  const { name, value, k, btnInline, datas, dataIndex = "id", change } = props;

  const valueChange = (e, val, isExclusive) => {
    let newArray = JSON.parse(JSON.stringify(value));

    if (!newArray.includes(val)) {
      newArray.push(val);
    } else {
      newArray = newArray.filter((a) => a !== val);
    }
    change(
      {
        target: {
          name: props.name,
          uniqueValue: value,
          value: newArray,
          formArray: e,
          isExclusive: isExclusive,
          k: k,
        },
      },
      k
    );
  };

  return (
    <LabelContainer {...props} value={value}>
      <div
        className={
          " d-flex flex-fill " +
          (btnInline ? "d-flex flex-row flex-wrap " : "btn-group")
        }
      >
        {datas.map((data, dk) => {
          let isActive = value.includes(data[dataIndex]);
          return (
            <ButtonItem
              key={"chkbtn" + props.name + props.id + dk}
              {...props}
              isActive={isActive}
              data={data}
              dk={dk}
              valueChange={valueChange}
            />
          );
        })}
      </div>
    </LabelContainer>
  );
};

export default CheckBtnListInput;
