import React, { useState, useEffect } from "react";
import Page from "../../../common/layout/Page";
import useStore from "../../../../context/useStore";
import Table from "../../../common/Table/Table";
import { DateTime } from "luxon";
import dataService from "../../../../helpers/dataService";
import dataListController from "./dataListController";
import DataListFilters from "./DataListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../../common/Pagination";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import { sidePanelActions } from "../../../../context/actions/sidePanelActions";

import Loader from "../../../common/Loader";
import RecipeTemplate from "./RecipeTemplate";
import PrintTechnicalSheetStandalone from "../PrintTechnicalSheetStandalone/PrintTechnicalSheetStandalone";

var searchTimeout = null;

const TechnicalSheetList = () => {
  const {
    state,
    dispatch,
    constants,
    errors,
    setErrors,
    isLoading,
    setIsLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
    nbResults,
  } = useApiDatas({
    resourcePath: "technicalsheets",
    page: "TechnicalSheetList",
    dependenciesPath: [
      "specificities",
      "consumertypes",
      "textures",
      "ingredients",
      "dishtypes",
    ],
    searchParams: {
      technicalSheet: { searchString: "" },
      page: 1,
    },
  });
  const { specificities, consumertypes, textures, ingredients, dishtypes } =
    otherDatas;
  const sidePanel_actions = sidePanelActions(state, dispatch);
  const [isDl, setIsDl] = useState(null);

  // const downloadAll = () => {
  //   var initElements = document.querySelectorAll(".btn-pdf-init");

  //   initElements.forEach((initElem, k) => {
  //     //console.log(initElem);
  //     setTimeout(() => {
  //       initElem.click();
  //     }, k * 300);
  //   });
  // };

  const printFt = (ft) => {
    console.log(ft);
    setIsDl(ft.id);
    dataService.post(
      "technicalsheets/" + ft.id + "/recipes",
      { multiplicator: 10 },
      (recipes) => {
        console.log("recipes", recipes);
        setIsDl(false);
        sidePanel_actions.updateSidePanel({
          isOpen: true,
          content: (
            <RecipeTemplate
              params={{
                recipes: textures
                  .map((t) => ({
                    texture: t,
                    recipeData:
                      recipes[consumertypes.find((cs) => cs.isReferent).id][
                        t.id
                      ],
                    dishtypes,
                    ingredients,
                    multiplicator: 10,
                  }))
                  .filter((t) => t.texture.isReferent),
                consumerType: consumertypes.find((cs) => cs.isReferent),
                technicalSheet: ft,
                specificities: specificities,
              }}
            />
          ),
        });
      }
    );
  };

  return (
    <Page
      title="Liste des fiches techniques"
      errors={errors}
      isLoading={isLoading}
    >
      <div className="row">
        <DataListFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            nbResults={nbResults}
            isLoading={isLoading}
            fields={[
              { name: "Nom", path: "name" },
              {
                name: "Actions",
                path: "*",
                render: (el) => (
                  <>
                    {isDl == el.id ||
                    !specificities ||
                    !consumertypes ||
                    !textures ||
                    !ingredients ||
                    !dishtypes ? (
                      <Loader />
                    ) : (
                      <>
                        <button
                          className="btn btn-primary"
                          onClick={() => printFt(el)}
                        >
                          <i className="fa fa-download" />
                        </button>
                      </>
                    )}
                  </>
                ),
              },
            ]}
            datas={searchDatas}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default TechnicalSheetList;
