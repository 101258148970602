import React from "react";

const ContractTotal = ({ contracts }) => {
  return (
    <div className="row mt-3">
      <div className="col-12 mb-2">
        <h1 style={{ fontSize: 20 }}>Quantité par contrat</h1>
      </div>
      <div className="col-4">
        <div className="custom-card h-100  d-flex flex-column justify-content-between">
          <div>
            {contracts.map((contract) => (
              <div className=" d-flex justify-content-between align-items-center border-bottom">
                <div>{contract.name}</div>
                <div>{contract.qty}</div>
              </div>
            ))}
          </div>
          <div className="mt-2">
            <div className="d-flex justify-content-between text-secondary font-weight-bold ">
              <div>Quantité totale</div>
              <div>
                {
                  contracts.reduce(
                    (ca, cb) => {
                      return {
                        qty: (ca.qty ? ca.qty : 0) + (cb.qty ? cb.qty : 0),
                      };
                    },
                    { qty: 0 }
                  ).qty
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractTotal;
