import { DateTime } from "luxon";
import React from "react";
import { modalActions } from "../../../../../context/actions/modalActions";
import useStore from "../../../../../context/useStore";
import TimelineHistory from "../../../../common/Timeline/TimelineHistory";

const UpdateHistory = ({ history }) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);

  return <TimelineHistory events={history} />;
};

export default UpdateHistory;
