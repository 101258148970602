import React from "react";
import Control from "../../../../common/Controls/Control";
import useSites from "../../../../common/Hooks/useSites";

const SiteSelector = ({ site, setSite }) => {
  const { sites } = useSites();

  return (
    <Control
      label="Site"
      name="site"
      type="select"
      value={site}
      change={(e) => setSite(e.target.value)}
      datas={sites}
      dataIndex="id"
      dataLabel="name"
      placeholder="Tous"
    />
  );
};

export default SiteSelector;
