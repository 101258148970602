/*
order //commander
manage_account //CRUD compte client
manage_billing //voir la facturation
manage_technicalsheets //consulter les fiches techniques


*/

const roleConfig = {
  OWNER: [],
  COLLABORATOR: ["manage_account"],
};

export default roleConfig;
