import React from "react";

const ContractItem = ({ day, moment, contract, ...props }) => {
  return (
    <div className="d-flex flex-row mb-2">
      <div
        className="p-2 d-center text-center bg-light"
        style={{
          width: "15%",
          borderBottomRightRadius: 5,
          borderTopRightRadius: 5,
          fontSize: 12,
          writingMode: "tb-rl",
          transform: "rotate(180deg)",
        }}
      >
        {contract.name}
      </div>
      {props.children}
    </div>
  );
};

export default ContractItem;
