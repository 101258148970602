import React, { useState } from "react";
import Page from "../../common/layout/Page";
import Login from "../Login/Login";
import { Link } from "react-router-dom";
import TextCard from "./TextCard";
import Footer from "../../common/layout/Footer";

const Index = () => {
  const [showLogin, setShowLogin] = useState(false);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 py-3">
            <img src="/images/logo.png" width="111" />
          </div>

          <div
            className="col-12 col-md-6"
            style={{
              backgroundImage: "url(/images/pano12.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
          <div className="col-12 col-md-6 p-5 bg-secondary text-white">
            <h1 className="text-white mb-4">
              Restauration &<br />
              services hôteliers
            </h1>
            <p>
              Avec plus de 1400 repas par jour, notre PME sait rester réactive
              et disponible afin d’améliorer chaque jour notre qualité pour
              satisfaire nos clients. C’est également dans cet objectif, et pour
              rester performant tout en protégeant l’environnement, que
              Qualicarry s’oriente vers la dématérialisation et
              l’informatisation de son organisation.
            </p>
            <div
              className="w-100 mt-4 d-flex justify-content-around align-items-center"
              style={{ gap: "1rem" }}
            >
              <button className="btn btn-primary d-center flex-fill">
                Nous contacter
              </button>
              <button
                onClick={() => setShowLogin(true)}
                className="btn btn-outline-primary d-center flex-fill text-white"
              >
                Me connecter
              </button>
            </div>
          </div>
        </div>
      </div>
      {showLogin && <Login />}
      <div className="container">
        <div className="row d-center mt-5">
          <div className="col-12 col-md-6 p-5">
            <h2 className="mb-3">Champ d'action</h2>
            <p>
              Qualicarry, c’est avant tout une équipe dynamique et motivée au
              service des autres. Dans la restauration collective depuis plus
              d’une dizaine d’années, nous sommes les partenaires des plus
              fragiles à nourrir. En effet, notre champ d’action comprend :
            </p>
          </div>
          <div className="col-12 col-md-6">
            <img src="/images/champ_action.png" style={{ maxWidth: "100%" }} />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light mt-5 py-5">
        <div className="container">
          <h2 className="text-center mb-4 mt-3">Nos valeurs</h2>
          <div className="row ">
            <TextCard
              title="Le respect de la culture alimentaire de La Réunion"
              text="Qualicarry s’est engagée à réaliser des plats respectant ces
                traditions métissées avec des menus très variés sur un cycle de
                6 semaines."
            />
            <TextCard
              title="La Qualité et le respect des consommateurs"
              text={
                <>
                  La Qualité et le respect des consommateurs via : <br />- Le
                  pôle QHSE et la mise en place d’une démarche HACCP. <br />-
                  L’adaptation aux différentes contraintes des clients en termes
                  de régimes alimentaires (sans sel, ...) et de textures.
                </>
              }
            />
            <TextCard
              title="L’engagement éthique et environnemental"
              text="Qualicarry privilégie les produits en circuits-courts ou
                provenant du commerce équitable. De plus, elle s’engage pour
                l’humain, notamment avec l’embauche de jeunes en CDI ou avec
                l’intégration de personnes en situation de handicap dans nos
                équipes actuelles."
            />
            <TextCard
              title="Le plaisir avant tout"
              text="Que ce soit celui de nos clients ou celui de nos employés, nous
                avons fait du plaisir et du goût nos piliers principaux. Nous
                proposons donc des plats de qualité, cuisinés par des
                passionnés, que vous aurez plaisir à partager."
            />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div className="container">
          <Footer />
          <div className="d-center pb-4">
            Logiciel développé par Qualicarry - financement Préfecture de La
            Réunion - Bop 112
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
