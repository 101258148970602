import React, { useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import Control from "../../../../common/Controls/Control";

const OrderPrint = ({ order, DAYS, MOMENTS }) => {
  const slotOrders = order.order.slotOrders;
  const slotRefs = order.order.slotRefs;
  const [displayedMoments, setDisplayedMoments] = useState(MOMENTS.map((m) => m.id));
  const [isLoading, setIsLoading] = useState(false);

  const updateDisplayedMoments = (e) => {
    setIsLoading(true);
    setDisplayedMoments(e.target.value);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  var clientName = order.sites.find((c) => c.id == order.utils.selectedSite).name;
  var period = `du ${DateTime.fromISO(order.utils.selectedDate)
    .setLocale("fr")
    .toFormat("dd MMMM yyyy")} au ${DateTime.fromISO(order.utils.selectedDate)
    .setLocale("fr")
    .endOf("week")
    .toFormat("dd MMMM yyyy")}`;

  const styles = StyleSheet.create({
    momentName: {
      fontSize: 12,
      fontWeight: "600",
      marginBottom: 3,
    },
    dayName: {
      fontSize: 10,
      fontWeight: "600",
      display: "block",
      color: "#456800",
      marginBottom: 2,
    },
    dayBlock: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      flex: "1 1 auto",
    },
    slot: {
      fontSize: 8,
      display: "block",
      borderLeftColor: "white",
      borderRightColor: "white",
      borderTopColor: "white",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: "#becaa6",
      paddingBottom: 2,
      marginBottom: 2,
      width: "100%",
    },
    slotQty: {
      color: "#456800",
      fontSize: 11,
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      fontWeight: "800",
      paddingTop: 2,
    },
  });

  return (
    <>
      <Control
        label="Moments à imprimer"
        datas={MOMENTS}
        value={displayedMoments}
        type="checkBtnList"
        dataIndex="id"
        dataLabel="name"
        change={updateDisplayedMoments}
        btnInline
      />
      {isLoading ? null : (
        <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
          <Document>
            {MOMENTS.filter((m) => displayedMoments.includes(m.id)).map((moment, mk) => (
              <Page size="A4" style={{ padding: 10 }} orientation="landscape">
                <View
                  style={{
                    paddingBottom: 2,
                  }}
                >
                  <Text style={{ display: "block", textAlign: "center", fontSize: 10 }}>
                    {clientName} // Récapitulatif des repas commandés {period}
                  </Text>
                </View>
                <View>
                  <Text style={{ ...styles.momentName }}>{moment.name}</Text>
                </View>
                <View
                  style={{
                    ...styles.dayBlock,
                    width: "100%",
                  }}
                >
                  {DAYS.map((day, dk) => {
                    var slots = slotOrders.filter((s) => s.day == day.id && s.moment == moment.id);

                    return (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: 100 / 7 + "%",
                        }}
                      >
                        <Text style={{ ...styles.dayName }}>{day.name}</Text>
                        {slots
                          .filter((s) => s.qty > 0)
                          .map((slot, sk) => {
                            console.log(slot.consumptionProfile);
                            var consumptionProfile =
                              order.order.consumptionProfiles.find(
                                (cs) => cs.id == slot.consumptionProfile
                              ) || {};
                            return (
                              <View
                                style={{
                                  ...styles.slot,
                                }}
                              >
                                <View>
                                  <Text
                                    style={{
                                      color: "blue",
                                      fontWeight: "800",
                                      fontSize: 9,
                                    }}
                                  >
                                    > {consumptionProfile.name} x {slot.qty}
                                  </Text>
                                </View>
                                {slot.slotRefs
                                  .sort((a, b) => {
                                    var getA = slotRefs.find((sl) => sl.id == a)
                                      ? slotRefs.find((sl) => sl.id == a).order
                                      : 999;
                                    var getB = slotRefs.find((sl) => sl.id == b)
                                      ? slotRefs.find((sl) => sl.id == b).order
                                      : 999;
                                    return getA > getB ? 1 : -1;
                                  })
                                  .map((s) => {
                                    var name = slotRefs.find((sl) => sl.id == s)
                                      ? slotRefs.find((sl) => sl.id == s).technicalSheet.name
                                      : "N.A";
                                    return (
                                      <View>
                                        <Text>{name}</Text>
                                      </View>
                                    );
                                  })}
                              </View>
                            );
                          })}
                      </View>
                    );
                  })}
                </View>
              </Page>
            ))}
          </Document>
        </PDFViewer>
      )}
    </>
  );
};

export default OrderPrint;
