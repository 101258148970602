import React from "react";
import useStore from "../../../../context/useStore";

const DayBlock = ({ day, moment, colorGreen, colorBlue, style, datas }) => {
  const [state] = useStore();
  const { DAYS, CONSUME_TIMES } = state.constants.items;

  var dayName = DAYS.find((d) => d.id == day).name;
  var dayDatas = datas.find((d) => d.day == day) || null;

  const fontSizes = {
    moment: 14,
    dishType: 13,
    technicalSheet: 12,
    alternative: 11,
  };
  const borderBigGreen = {
    // borderTop: `2px solid ${colorGreen}`,
    // borderLeft: `2px solid ${colorGreen}`,
    // borderRight: `5px solid ${colorGreen}`,
    // borderBottom: `5px solid ${colorGreen}`,
    // background: "#faffef",
    border: `1px solid ${colorGreen}`,
    borderRadius: 10,
  };
  const borderBigBlue = {
    // borderTop: `2px solid ${colorBlue}`,
    // borderLeft: `2px solid ${colorBlue}`,
    // borderRight: `5px solid ${colorBlue}`,
    // borderBottom: `5px solid ${colorBlue}`,
    // background: "#faffef",
    border: `1px solid ${colorBlue}`,
    borderRadius: 10,
  };
  const green = "#9ab35a";
  const greenDark = "#456800";
  const blue = "#5a85b3";
  const blueDark = "#003768";

  console.log("dayDatas", dayDatas);
  return !dayDatas ? null : (
    <div className="">
      <div
        style={{
          width: "100%",
          minHeight: 40,
          borderRadius: 10,
        }}
      >
        {dayDatas ? (
          <ul style={{ listStyle: "none", padding: 0 }}>
            {dayDatas.moments
              .filter((mom) => mom.moment == moment)
              .map((mom, mk) => {
                const borderStyle = mom.moment == 3 ? borderBigGreen : borderBigBlue;
                const color = mom.moment == 3 ? green : blue;
                const colorDark = mom.moment == 3 ? greenDark : blueDark;
                const clientContracts = mom.clientContracts;
                return (
                  <li>
                    {clientContracts.map((cc, cck) => (
                      <li className="mb-1">
                        {cck > 0 && (
                          <div
                            className="text-center"
                            style={{
                              fontSize: "11px",
                              lineHeight: "11px",
                              color: "white",
                              transform: "rotate(-45deg) scale(1) translate(9px, -8px)",
                              display: "inline-flex",
                              padding: "5px",
                              background: "#de386d",
                              borderRadius: "100%",
                              position: "absolute",
                              left: "80%",
                            }}
                          >
                            <strong>OU</strong>
                          </div>
                        )}

                        <div
                          className="px-2 pt-2"
                          style={{
                            ...borderStyle,
                            borderRadius: 10,
                            background: "rgba(255,255,255,0.7)",
                            //background: mom.moment == 3 ? "#faffef" : "rgb(239 246 255)",
                          }}
                        >
                          {/* {cc.dishTypes.map((dishType, dk) => {
                          return (
                            <> */}
                          {/* <strong
                            style={{
                              fontSize: fontSizes.dishType,
                              fontWeight: "600",
                            }}
                          >
                            <i>{dishType.dishType}</i>
                          </strong> */}
                          <div className=" mb-1 " style={{}}>
                            <ul
                              className="mb-0"
                              style={{
                                paddingLeft: 0,
                                listStyle: "none",
                              }}
                            >
                              {cc.technicalSheets &&
                                cc.technicalSheets.map((ts, tsk) => {
                                  let alternatives = [...new Set(ts.alternatives || [])];
                                  return (
                                    <li key={`d${tsk}`}>
                                      <div className="d-flex justify-content-start align-items-start flex-row ">
                                        <i
                                          className="fa fa-chevron-right  pt-1  "
                                          style={{ fontSize: 8, color }}
                                        />
                                        <div className="ml-2 mb-1" style={{ lineHeight: "13px" }}>
                                          <strong
                                            className=""
                                            style={{
                                              fontSize: fontSizes.technicalSheet,
                                              color: colorDark,
                                            }}
                                          >
                                            {ts.name}
                                          </strong>
                                          {ts.alternatives && (
                                            <>
                                              {alternatives.map((alt, ak) => (
                                                <span
                                                  key={`d${tsk}${ak}`}
                                                  style={{
                                                    fontSize: fontSizes.alternative,
                                                  }}
                                                >
                                                  <div>
                                                    <i className="mr-1">ou</i>{" "}
                                                    <strong
                                                      style={{
                                                        color: colorDark,
                                                      }}
                                                    >
                                                      {alt}
                                                    </strong>
                                                  </div>
                                                </span>
                                              ))}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          {/* </>
                          );
                        })} */}
                        </div>
                      </li>
                    ))}
                  </li>
                );
              })}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default DayBlock;
