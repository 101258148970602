import React from "react";

const TextCard = ({ title, text }) => {
  return (
    <div className="col-12 col-md-6 p-2" style={{ gap: "1rem" }}>
      <div className="bg-white h-100 w-100 p-5">
        <div
          style={{ fontSize: "1.05rem", fontWeight: "500" }}
          className="text-primary mb-3 d-block"
        >
          {title}
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default TextCard;
