import React, { useEffect, useState } from "react";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";
import useStats from "../useStats/useStats";
import {
  bioIds,
  calculateTotalLocal,
  calculateTotalSIQO,
  localIds,
  siqoIds,
} from "./egalimTools";

const Egalim = ({ search }) => {
  const [state] = useStore();
  const { HISTORIC_TYPES } = state.constants.items;
  const { getStat } = useStats();
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [totalLocal, setTotalLocal] = useState({ purchase: 0, weight: 0 });
  const [totalSIQO, setTotalSIQO] = useState({ purchase: 0, weight: 0 });
  const [bioItems, setBioItems] = useState([]);
  const [siqoItems, setSiqoItems] = useState([]);
  const [localItems, setLocalItems] = useState([]);
  const [otherItems, setOtherItems] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    var results = await getStat({ statName: "egalim", search }).catch(
      (err) => ({
        errors: err,
      })
    );
    setIsLoading(false);
    if (!results.errors) {
      setChartData(results);
      setBioItems(HISTORIC_TYPES.filter((h) => bioIds.includes(h.id)));
      setSiqoItems(HISTORIC_TYPES.filter((h) => siqoIds.includes(h.id)));
      setLocalItems(HISTORIC_TYPES.filter((h) => localIds.includes(h.id)));
      setOtherItems(
        HISTORIC_TYPES.filter(
          (h) =>
            !siqoIds.includes(h.id) &&
            !localIds.includes(h.id) &&
            !bioIds.includes(h.id) &&
            h.isEgalim
        )
      );
      setHasChanged(false);
    }
  };

  useEffect(() => {
    if (search) {
      setHasChanged(true);
    }
  }, [search]);

  useEffect(() => {
    if (chartData) {
      setTotalLocal(calculateTotalLocal(chartData, HISTORIC_TYPES));
      setTotalSIQO(calculateTotalSIQO(chartData, HISTORIC_TYPES));
    }
  }, [chartData]);

  console.log("bioItems", bioItems);
  console.log("siqoItems", siqoItems);
  console.log("otherItems", otherItems);

  const valueClassName = "font-weight-bold text-secondary d-center";

  return (
    <div>
      <table className="table mb-0 table-bordered w-100 bg-white hover-table rounded overflow-hidden shadow-sm">
        <thead>
          <tr>
            <th style={{ verticalAlign: "top" }}>
              EGALIM{" "}
              {hasChanged && (
                <button
                  onClick={fetchData}
                  className="btn btn-default load-stat"
                >
                  <i
                    className={`fa fa-refresh ${
                      isLoading ? "animated rotate infinite" : ""
                    }`}
                  />
                </button>
              )}
            </th>
            <th>% cumulé par rapport aux achats</th>
            <th>% cumulé par rapport au poids alimentaire traité</th>
          </tr>
        </thead>
        <tbody>
          {bioItems.map((h) => {
            var values = chartData ? chartData[h.id] : {};
            var isBio = h.id == "BIO";
            return (
              <tr className={isBio ? "bg-default" : ""}>
                <td
                  style={{
                    fontWeight: isBio ? "800" : "normal",
                  }}
                >
                  TOTAL BIO
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.purchase}%
                  </div>
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.weight}%
                  </div>
                </td>
              </tr>
            );
          })}
          <tr className="bg-default">
            <td
              style={{
                fontWeight: "800",
              }}
            >
              TOTAL LOCAL
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalLocal.purchase}%
              </div>
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalLocal.weight}%
              </div>
            </td>
          </tr>
          {localItems.map((h) => {
            var values = chartData ? chartData[h.id] : {};
            var isBio = h.id == "BIO";
            return (
              <tr className={isBio ? "bg-default" : ""}>
                <td
                  style={{
                    fontWeight: isBio ? "800" : "normal",
                  }}
                >
                  {h.name}
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.purchase}%
                  </div>
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.weight}%
                  </div>
                </td>
              </tr>
            );
          })}
          <tr className="bg-default">
            <td
              style={{
                fontWeight: "800",
              }}
            >
              TOTAL SIQO
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalSIQO.purchase}%
              </div>
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalSIQO.weight}%
              </div>
            </td>
          </tr>
          {siqoItems.map((h) => {
            var values = chartData ? chartData[h.id] : {};
            var isBio = h.id == "BIO";
            return (
              <tr className={isBio ? "bg-default" : ""}>
                <td
                  style={{
                    fontWeight: isBio ? "800" : "normal",
                  }}
                >
                  {h.name}
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.purchase}%
                  </div>
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.weight}%
                  </div>
                </td>
              </tr>
            );
          })}
          {/* {HISTORIC_TYPES.filter((h) => h.isEgalim).map((h) => {
            var values = chartData ? chartData[h.id] : {};
            var isBio = h.id == "BIO";
            return (
              <tr className={isBio ? "bg-default" : ""}>
                <td
                  style={{
                    fontWeight: isBio ? "800" : "normal",
                  }}
                >
                  {h.name}
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.purchase}%
                  </div>
                </td>
                <td>
                  <div
                    className={`${valueClassName} ${isBio ? "text-dark" : ""}`}
                  >
                    {values?.weight}%
                  </div>
                </td>
              </tr>
            );
          })}
          <tr className="bg-default">
            <td
              style={{
                fontWeight: "800",
              }}
            >
              TOTAL LOCAL
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalLocal.purchase}%
              </div>
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalLocal.weight}%
              </div>
            </td>
          </tr>
          <tr className="bg-default">
            <td
              style={{
                fontWeight: "800",
              }}
            >
              TOTAL SIQO
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalSIQO.purchase}%
              </div>
            </td>
            <td>
              <div className={`${valueClassName} text-dark`}>
                {totalSIQO.weight}%
              </div>
            </td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default Egalim;
