import React from "react";
import { Link } from "react-router-dom";
import { sidePanelActions } from "../../../context/actions/sidePanelActions";
import useStore from "../../../context/useStore";

const SidePanel = () => {
  const [state, dispatch] = useStore();
  const actions = sidePanelActions(state, dispatch);
  const sidePanelProps = state.sidePanel;

  return (
    <>
      <div
        onClick={() => {
          actions.updateSidePanel({ isOpen: false, content: null });
        }}
        className="position-fixed w-100 h-100 align-items-center justify-content-center"
        style={{
          display: sidePanelProps.isOpen ? "flex" : "none",
          top: 0,
          left: 0,
          background: "rgb(0 0 0 / 54%)",
          zIndex: "9999",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div
          className={
            "bg-white position-fixed p-3 flex-column align-items-start " +
            (sidePanelProps.isOpen ? "animated fadeInRight faster" : "")
          }
          onClick={(e) => e.stopPropagation()}
          style={{
            width: "42%",
            height: "100%",
            maxWidth: "400",
            borderRadius: 5,
            overflowY: "scroll",
            right: 0,
            top: 0,
            zIndex: "9999",
            minWidth: 450,
          }}
        >
          {sidePanelProps.backUrl && (
            <Link className="btn btn-link" to={sidePanelProps.backUrl}>
              <i className="fa fa-arrow-left mr-2" />
              retour à la page d'origine
            </Link>
          )}
          {sidePanelProps.content}
          {/* 
          <div
            className="d-flex align-items-center justify-content-end w-100 "
            style={{ position: "fixed", bottom: 0, right: 0 }}
          >
            {sidePanelProps.actions}
            <button
              onClick={() =>
                actions.updateSidePanel({ isOpen: false, content: null })
              }
              className="btn btn-default w-100"
            >
              Fermer
            </button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SidePanel;
