const initialState = {
  id: "infotext-sidepanel",
  title: "",
  isOpen: false,
  setIsOpen: () => {},
  content: "",
  backUrl: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SIDEPANEL_UPDATE":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
