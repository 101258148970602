import React from "react";

const ControlValidIcon = (props) => {
  return (
    <i
      style={{ position: "absolute", left: -21, bottom: 8 }}
      className={
        "fa fa-" +
        (props.error[props.name] ? "times" : "check") +
        " text-" +
        (props.error[props.name] ? "danger" : "light") +
        " animated fadeInLeft faster"
      }
    />
  );
};

export default ControlValidIcon;
