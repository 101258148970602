import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import { DateTime } from "luxon";
import Loader from "../../../common/Loader";
import Colors from "../../../../theme/variables.scss";
import tools from "../../../../helpers/tools";
import rubik from "./rubik.ttf";
import useStore from "../../../../context/useStore";
import Control from "../../../common/Controls/Control";
import dataService from "../../../../helpers/dataService";

const MyDoc = ({
  datas,
  styles,
  constants,
  consumerType,
  technicalSheet,
  isPrintClient,
  specificities,
  preparations,
}) => {
  const { recipeData, texture, dishTypes, ingredients, multiplicator } = datas;
  const { ALLERGENS, INGREDIENT_UNITS } = constants;
  Font.register({
    family: "Rubik",
    format: "truetype",
    fonts: [
      {
        src: rubik,
      },
    ],
  });

  var styles = StyleSheet.create({
    textBig: {
      fontSize: 18,
    },
    textMedium: {
      fontSize: 14,
    },
    textSmall: {
      fontSize: 9,
    },
    subTitle: {
      fontSize: 12,
      marginBottom: 5,
      textTransform: "uppercase",
      color: Colors.primary,
    },
    info: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: 400,
      borderBottom: "1pt solid grey",
      marginBottom: 5,
    },
    label: {
      color: Colors.darkLight,
    },
    value: {
      color: Colors.primary,
    },
    col6: {
      width: "47%",
      marginBottom: 15,
    },
    listTitle: {
      fontSize: 11,
    },
    listItem: {
      fontSize: 9,
      color: Colors.lightDark,
    },
    row: {
      fontSize: 12,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    td: {
      padding: 5,
      border: "1pt solid grey",
      fontSize: 10,
    },
  });

  const allergenList = () => {
    var hasAllergens = false;
    var allergensUniq = [];
    recipeData.components.forEach((comp) => {
      var ing = ingredients.find((i) => i.id == comp.ingredient);
      if (ing.allergens.length) hasAllergens = true;
    });
    if (hasAllergens) {
      recipeData.components.forEach((comp, compk) => {
        var ing = ingredients.find((i) => i.id == comp.ingredient);
        if (ing.allergens.length) {
          ing.allergens.forEach((al) => {
            if (!allergensUniq.includes(al)) allergensUniq.push(al);
          });
        }
      });
    }
    // else (
    //   <View>
    //     <Text style={{ ...styles.listItem }}>-aucun allergène-</Text>
    //   </View>
    //   );

    return allergensUniq.length ? (
      allergensUniq.map((al, alk) => (
        <Text style={{ ...styles.listItem }} key={`allist${alk}`}>
          - {ALLERGENS.find((a) => a.id == al).name}
        </Text>
      ))
    ) : (
      <View>
        <Text style={{ ...styles.listItem }}>-aucun allergène-</Text>
      </View>
    );
  };

  const componentsList = () => {
    var comps = recipeData.components.map((ing) => ({
      ...ing,
      qty: (parseInt(multiplicator) * parseFloat(ing.qty)) / 10,
    }));

    console.log("comps", comps);

    return comps.map((ingr, ingrKey) => {
      var preparation = preparations.find((p) => p.id == ingr.preparation);
      console.log("preparation", preparation);
      var suffix = "";
      if (
        preparation.isSpecificEquivalentPacking &&
        preparation.specificEquivalentPackingUnit == "3"
      ) {
        var weight = preparation.packingWeight * 1000;
        var nbLiters = preparation.specificEquivalentPackingQty;
        var qty = ingr.qty;
        console.log(weight, nbLiters, qty);
        var qtyEq = Math.round(((nbLiters * qty) / weight) * 100) / 100;
        suffix = <>{"\n" + qtyEq + " LITRES"}</>;
      }
      return (
        <View
          key={"comp" + ingrKey}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Text style={{ ...styles.td, width: "50%", height: "100%" }}>
            {ingredients.find((i) => i.id == ingr.ingredient)?.name}
          </Text>
          <Text style={{ ...styles.td, width: "50%", height: "100%" }}>
            {ingr.qty} {INGREDIENT_UNITS.find((i) => i.id == ingr.unit)?.name}
            {suffix}
          </Text>
        </View>
      );
    });
  };

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const renderSpecitifities = () => {
    var specs = [];
    specificities.forEach((s, sk) => {
      var sp = technicalSheet.specificities.includes(s.id);
      if (sp)
        specs.push(
          <Text
            style={{
              margin: "5 2px",
              backgroundColor: Colors.secondary,
              color: "white",
              padding: 5,
            }}
            key={`spec${sp.id}${Math.random()}`}
          >
            {s.name}
          </Text>
        );
    });
    console.log("SPECS", specs);
    return specs;
  };

  return (
    <Page
      wrap
      size="A4"
      orientation="portrait"
      style={{ padding: "20px", fontFamily: "Rubik" }}
    >
      <View
        style={{
          textAlign: "center",
          color: Colors.secondary,
        }}
      >
        <Image src="/logo.png" style={{ width: 100 }} />

        <Text style={{ marginBottom: 5 }}>
          Fiche technique - {technicalSheet.name}
        </Text>
        <Text
          style={{
            fontSize: 9,
            marginBottom: 5,
          }}
        >
          pour 10 personnes
        </Text>
        <Text
          style={{
            fontSize: 12,
          }}
        >
          {consumerType.name} - Texture {datas.texture.name}
        </Text>
        <Text
          style={{
            fontSize: 10,
            color: "grey",
          }}
        >
          imprimé le {DateTime.local().toFormat("dd/MM/yyyy HH:mm")}
        </Text>
        <Text
          style={{
            fontSize: 10,
            color: "grey",
          }}
        >
          modifié le{" "}
          {recipeData.updatedAt
            ? DateTime.fromISO(recipeData.updatedAt).toFormat(
                "dd/MM/yyyy HH:mm"
              )
            : "-inconnu-"}
        </Text>
      </View>
      <View
        style={{
          fontSize: 7,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          marginBottom: 20,
        }}
      >
        {renderSpecitifities()}
      </View>
      <View style={{ ...styles.row }}>
        <View style={{ ...styles.col6 }}>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>% de perte brut->net</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.total1Dish.ratioBrutToCooked)}%
            </Text>
          </View>

          {isPrintClient && (
            <View style={{ ...styles.info }}>
              <Text style={{ ...styles.label }}>Coût total</Text>
              <Text style={{ ...styles.value }}>
                {tools.round(recipeData.total1Dish.totalCost * multiplicator)}€
              </Text>
            </View>
          )}

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Poids total final cuit</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(
                recipeData.total1Dish.totalWeightNet * multiplicator
              )}{" "}
              GRAMMES
            </Text>
          </View>

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Total de pièces</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.total1Dish.totalPieces * multiplicator)}{" "}
            </Text>
          </View>

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Valeur nutri. </Text>
            <Text style={{ fontSize: 8 }}>
              (1 portion de {tools.round(recipeData.total1Dish.totalWeightNet)}
              g)
            </Text>
            <Text style={{ ...styles.value }}>
              {tools.roundInt(recipeData.nutritionalValues?.perPortion)} kcal
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>Valeur nutri.</Text>
            <Text style={{ fontSize: 8 }}>(100g)</Text>
            <Text style={{ ...styles.value }}>
              {tools.roundInt(recipeData.nutritionalValues?.per100g)} kcal
            </Text>
          </View>

          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>% de produits BIO (Grammes)</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentBio.weight)} %
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>% de produits BIO (€)</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentBio.cost)} %
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>
              % de produits Locaux (Grammes)
            </Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentLocal.weight)} %
            </Text>
          </View>
          <View style={{ ...styles.info }}>
            <Text style={{ ...styles.label }}>% de produits Locaux (€)</Text>
            <Text style={{ ...styles.value }}>
              {tools.round(recipeData.totalPercentLocal.cost)} %
            </Text>
          </View>
        </View>
        <View style={{ ...styles.col6 }}>
          <Text style={{ ...styles.subTitle }}>Liste des allergènes</Text>
          {allergenList()}
        </View>
      </View>
      <View style={{ ...styles.row }}>
        <View style={{ ...styles.col6 }}>
          <Text style={{ ...styles.subTitle }}>
            Composants pour {multiplicator} portion(s)
          </Text>
          {componentsList()}
        </View>
        <View style={{ ...styles.col6 }}>
          <Text style={{ ...styles.subTitle }}>Méthodologie</Text>
          <Text style={{ ...styles.textSmall }}>
            {recipeData.methodology && stripHtml(recipeData.methodology)}
          </Text>
        </View>
      </View>
    </Page>
  );
};

const RecipeTemplate = ({ params }) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isInit, setIsInit] = useState(true);
  const [datas, setDatas] = useState(params);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isPrintClient, setIsPrintClient] = useState(false);
  const [preparations, setPreparations] = useState(false);

  useEffect(() => {
    getPreparations();
  }, []);

  const getPreparations = () => {
    var preparationsToGet = [];
    params.recipes.forEach((recipe) => {
      recipe.recipeData.components.forEach((component) => {
        if (!preparationsToGet.includes(component.preparation))
          preparationsToGet.push(component.preparation);
      });
    });
    console.log("preparationsToGet", preparationsToGet);
    dataService.post(`preparations/search`, preparationsToGet, setPreparations);
  };

  return isLoading || !preparations ? (
    <Loader />
  ) : errors.other ? (
    <div className="alert alert-danger">{errors.other}</div>
  ) : !isInit ? null : (
    <div className="text-center">
      <PDFDownloadLink
        className="btn btn-primary mx-auto my-3 d-center"
        document={
          <Document>
            {params.recipes.map((p, pk) => (
              <MyDoc
                key={"tex" + pk}
                datas={p}
                consumerType={params.consumerType}
                constants={constants}
                technicalSheet={params.technicalSheet}
                isPrintClient={isPrintClient}
                specificities={params.specificities}
                preparations={preparations}
              />
            ))}
          </Document>
        }
        fileName={`Fiche technique ${params.technicalSheet.name} - ${
          params.consumerType.name
        } - ${
          params.recipes.length > 1
            ? "Toutes textures"
            : "Texture " + params.recipes[0].texture.name
        }.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? "Chargement..." : "Télécharger le document"
        }
      </PDFDownloadLink>
      {/* <PDFViewer style={{ width: "100%", height: "100%", border: 0 }}>
        <MyDoc datas={datas}  />
      </PDFViewer> */}
    </div>
  );
};

export default RecipeTemplate;
