const initialState = {
  isLoading: false,
  items: {},
  actionUrls: {},
  errors: {},
  isInit: false,
};

const initItems = (payload, state) => {
  const { items, actionUrls } = payload;
  return { ...state, items, actionUrls, isLoading: false, isInit: true };
};

// const addItem = (item, state) => {
//   const newItem = [...state.items, item];
//   return { ...state, items: newItem };
// };
// const removeItem = (idx, state) => {
//   const newItems = [...state.items].filter((t, tk) => tk != idx);
//   return { ...state, items: newItems };
// };
const updateItem = ({ item, value }, state) => {
  return { ...state, items: { ...state.items, [item]: value } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "INIT_ITEMS":
      return initItems(action.payload, state);
    case "IS_INIT":
      return { ...state, isInit: action.payload };
    // case ADD_ITEM:
    //   return addItem(action.payload, state);
    // case REMOVE_ITEM:
    //   return removeItem(action.payload, state);
    case "UPDATE_ITEM":
      return updateItem(action.payload, state);
    case "SAVING_SITE":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, savingSite: action.payload },
        },
      };
    case "SAVING_CONTRACT":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, savingContract: action.payload },
        },
      };
    case "SAVING_USER":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, savingUser: action.payload },
        },
      };

    case "SET_ERRORS":
      return {
        ...state,
        items: {
          ...state.items,
          utils: {
            ...state.items.utils,
            errors: action.payload,
            savingSite: false,
            savingContract: false,
          },
        },
      };
    case "SET_ENTITY_SELECTED":
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, entitySelected: action.payload },
        },
      };
    case "UPDATE_SITES":
      return {
        ...state,
        items: {
          ...state.items,
          sites: action.payload,
          utils: { ...state.items.utils, savingSite: false },
        },
      };
    case "UPDATE_CONTRACTS":
      return {
        ...state,
        items: {
          ...state.items,
          contracts: action.payload,
          utils: { ...state.items.utils, savingContract: false },
        },
      };
    case "UPDATE_USERS":
      return {
        ...state,
        items: {
          ...state.items,
          users: action.payload,
          utils: { ...state.items.utils, savingUser: false },
        },
      };
    default:
      return state;
  }
};
