

const initialState = {
  isLoading: false,
  items: [],
  item: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LOAD_CONSTANT":
      return {
        ...state,
        isLoading: action.payload
      };
    case "GET_CONSTANTS":
      return {
        ...state,
        items: action.payload,
        isLoading: false
      };

    default:
      return state;
  }
}
