import React, { useReducer } from "react";
import ItemContext from "./ItemContext";

export default function ItemStore(props) {
  const initialState = props.rootReducer(props.initialValue, {
    type: "__INIT__",
  });
  const [state, dispatch] = useReducer(props.rootReducer, initialState);
  return (
    <ItemContext.Provider value={[state, dispatch]}>
      {props.children}
    </ItemContext.Provider>
  );
}
