import React from "react";
import LabelContainer from "../LabelContainer";

const CheckboxListInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  datas = [],
  dataIndex = "id",
  dataLabel = "name",
  dataLabelIndex = false,
  ...props
}) => {
  const valueChange = (e, val, isExclusive) => {
    let newArray = JSON.parse(JSON.stringify(value));

    if (e.target.checked) {
      newArray.push(val);
    } else {
      newArray = newArray.filter((a) => a !== val);
    }
    change(
      {
        target: {
          name: props.name,
          uniqueValue: value,
          value: newArray,
          formArray: e,
          isExclusive: isExclusive,
        },
      },
      k
    );
  };

  return (
    <LabelContainer {...props} value={value}>
      <div className="my-3 w-100">
        {datas.map((data, k) => (
          <div
            key={"chklist" + props.name + props.id + k}
            className={"my-0 custom-control  custom-switch"}
          >
            <input
              type="checkbox"
              className={"custom-control-input " + inputClassName}
              disabled={disabled ? "disabled" : false}
              name={props.name}
              id={
                k != -1
                  ? "customSwitch" + props.name + "-" + k
                  : "customSwitch" + props.name
              }
              onChange={(e) =>
                valueChange(e, data[dataIndex], data.isExclusive)
              }
              checked={value.includes(data[dataIndex]) ? "checked" : false}
            />
            <label
              className="custom-control-label p-3 d-flex align-items-center border-0 "
              style={{ minHeight: 0, cursor: "pointer" }}
              htmlFor={
                k != -1
                  ? "customSwitch" + props.name + "-" + k
                  : "customSwitch" + props.name
              }
            >
              <strong>
                {dataLabelIndex
                  ? data[dataLabel][dataLabelIndex]
                  : data[dataLabel]}
              </strong>
            </label>
          </div>
        ))}
      </div>
    </LabelContainer>
  );
};

export default CheckboxListInput;
