import tools from "../../../../../helpers/tools";
export const bioIds = ["BIO"];
export const localIds = ["LOCAL_1", "LOCAL_2", "LOCAL_3"];
export const siqoIds = [
  "SIQO_1",
  "SIQO_2",
  "SIQO_3",
  "SIQO_4",
  "SIQO_5",
  "SIQO_6",
  "SIQO_7",
  "SIQO_8",
];
export const calculateTotalLocal = (chartData, HISTORIC_TYPES) => {
  var totalPurchase = 0;
  var totalWeight = 0;
  HISTORIC_TYPES.filter((h) => h.isEgalim && localIds.includes(h.id)).forEach(
    (h) => {
      var values = chartData[h.id];
      totalPurchase += parseFloat(values.purchase);
      totalWeight += parseFloat(values.weight);
    }
  );
  return {
    purchase: tools.round(totalPurchase),
    weight: tools.round(totalWeight),
  };
};
export const calculateTotalSIQO = (chartData, HISTORIC_TYPES) => {
  var totalPurchase = 0;
  var totalWeight = 0;
  HISTORIC_TYPES.filter((h) => h.isEgalim && siqoIds.includes(h.id)).forEach(
    (h) => {
      var values = chartData[h.id];
      totalPurchase += parseFloat(values.purchase);
      totalWeight += parseFloat(values.weight);
    }
  );
  return {
    purchase: tools.round(totalPurchase),
    weight: tools.round(totalWeight),
  };
};
