import React from "react";
import useStoreState from "../useStoreState";

const ContractsContainer = ({ site }) => {
  const { state, items, items_actions } = useStoreState();
  const { client, sites, contracts } = { ...items };

  return (
    <ul>
      {contracts
        .filter((c) => c.sites.includes(site.id))
        .map((c, ck) => {
          return (
            <li key={`contract${site.id}${c.id}`}>
              <span

              // onClick={(e) =>
              //   items_actions.setEntitySelected("contract", c.id)
              // }
              >
                {c.name}
              </span>
              {/* <button
                className="btn btn-sm"
                onClick={() => items_actions.removeContract(c.id)}
              >
                <i className="fa fa-times text-danger" />
              </button> */}
            </li>
          );
        })}
    </ul>
  );
};

export default ContractsContainer;
