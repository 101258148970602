import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Control from "../../../../common/Controls/Control";
var fr = require("apexcharts/dist/locales/fr.json");

const BarChart = ({ categories, series, seriesName }) => {
  const [chartType, setChartType] = useState("bar");
  const [chartState, setChartState] = useState({
    options: {
      fill: {
        colors: ["rgb(154, 179, 90)"],
      },
      chart: {
        id: "basic-bar",
        locales: [fr],
        defaultLocale: "fr",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: "series-1",
        data: [],
      },
    ],
  });

  useEffect(() => {
    setChartState({
      ...chartState,
      options: {
        ...chartState.options,
        xaxis: {
          categories,
        },
      },
      series: [
        {
          name: seriesName,
          data: series,
        },
      ],
    });
  }, [categories, series]);

  return (
    <>
      {chartType == "bar" ? (
        <>
          <Chart
            options={chartState.options}
            series={chartState.series}
            type={"bar"}
            width="100%"
          />
        </>
      ) : (
        <Chart
          options={chartState.options}
          series={chartState.series}
          type={"line"}
          width="100%"
        />
      )}
      <div className="d-flex justify-content-end">
        <Control
          type="btnList"
          change={(e) => setChartType(e.target.value)}
          value={chartType}
          datas={[
            { id: "bar", name: "", icon: "chart-bar" },
            { id: "line", name: "", icon: "chart-line" },
          ]}
          dataIndex="id"
          dataLabel="name"
          dataIcon="icon"
          iconSize={18}
          btnInline
          inputGroupStyle={{ paddingLeft: 0, background: "transparent" }}
          labelStyle={{ width: "auto" }}
        />
      </div>
    </>
  );
};

export default BarChart;
