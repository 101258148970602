import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import UserInfo from "./UserInfo";
import Colors from "../../../theme/variables.scss";

const Profile = () => {
  const [state, dispatch] = useStore();

  return (
    <Page title="Profil" back="/">
      <UserInfo />
    </Page>
  );
};

export default Profile;
