import { modalActions } from "../../../../context/actions/modalActions";
import { sidePanelActions } from "../../../../context/actions/sidePanelActions";
import { itemsActions } from "./Context/actions/ItemsActions";
import useItemStore from "./Context/useItemStore";
import useGlobalState from "./ItemGlobalState";

const useStoreState = () => {
  const [state, dispatch] = useItemStore();
  const { gState, gDispatch } = useGlobalState();
  const mActions = modalActions(gState, gDispatch);
  const spActions = sidePanelActions(gState, gDispatch);
  const items_actions = itemsActions(state, dispatch);
  return {
    state,
    dispatch,
    gState,
    gDispatch,
    items_actions: items_actions,
    items: state.items,
    mActions,
    spActions,
  };
};

export default useStoreState;
