import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import dataService from "../../../helpers/dataService";

const UserInfo = () => {
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [passwordsEqual, setPasswordsEqual] = useState(true);
  const [user, setUser] = useState({
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirm: "",
  });
  const [modifyAvatar, setModifyAvatar] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dataService.get(
      `customers/me`,
      (datas) => {
        setUser(datas);
      },
      setErrors,
      () => setIsLoading(false)
    );
  }, []);

  useEffect(() => {
    setPasswordsEqual(user.password === user.passwordConfirm);
  }, [user.password, user.passwordConfirm]);

  const updateInput = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };
  const save = () => {
    setIsLoading(true);
    dataService.patch(
      `customers/me`,
      user,
      (datas) => {
        setUser(datas);
        setErrors({});
        toast.success("Vos informations ont bien été mises à jour");
        setShowPassword(false);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };
  const savePassword = () => {
    setIsLoading(true);
    dataService.patch(
      `customers/me/password`,
      { password: user.password },
      (datas) => {
        setErrors({});
        toast.success("Votre mot de passe a bien été modifié");
        setShowPassword(false);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };
  return (
    <div className="custom-card w-100">
      <div className="row">
        <div className="col-12">
          <Control
            label="Civilité"
            type="select"
            name="gender"
            value={user.gender}
            change={updateInput}
            datas={[
              { id: 1, name: "Monsieur" },
              { id: 2, name: "Madame" },
            ]}
            error={errors}
          />
          <Control
            label="Nom"
            type="text"
            name="lastname"
            value={user.lastname}
            change={updateInput}
            error={errors}
          />
          <Control
            label="Prénom"
            type="text"
            name="firstname"
            value={user.firstname}
            change={updateInput}
            error={errors}
          />
          <Control
            label="Email"
            type="email"
            name="email"
            value={user.email}
            change={updateInput}
            error={errors}
          />
          <Control
            label="Téléphone"
            type="tel"
            name="phone"
            value={user.phone}
            change={updateInput}
            error={errors}
          />

          <div className="d-flex align-items-center justify-content-md-between justify-content-center flex-wrap mt-3">
            <button
              className={`btn btn-secondary animated fast ${
                showPassword ? "fadeOut" : "fadeIn"
              }`}
              onClick={() => setShowPassword((s) => !s)}
            >
              {isLoading ? (
                <Loader color="white" />
              ) : (
                "Changer mon mot de passe"
              )}
            </button>
            <button className="btn btn-primary" onClick={save}>
              {isLoading ? <Loader color="white" /> : "Mettre à jour"}
            </button>
          </div>
        </div>

        {showPassword && (
          <div className={`col-12 mt-4 animated faster fadeInUp `}>
            <Control
              label="Mot de passe (laissez vide pour inchangé)"
              type="password"
              name="password"
              value={user.password}
              change={updateInput}
              error={errors}
              setError={setErrors}
              autocomplete="new-password"
              required={true}
            />

            <Control
              label="Retapez le mot de passe"
              type="password"
              name="passwordConfirm"
              value={user.passwordConfirm}
              change={updateInput}
              error={errors}
              setError={setErrors}
              isValid={
                user.passwordConfirm == user.password &&
                user.password &&
                user.password != ""
              }
              required={true}
            />
            <div className="text-center text-md-right">
              <button
                className="btn btn-primary"
                disabled={
                  user.passwordConfirm != user.password ||
                  !user.password ||
                  user.password == ""
                }
                onClick={savePassword}
              >
                {isLoading ? (
                  <Loader color="white" />
                ) : (
                  "Mettre à jour mon mot de passe"
                )}
              </button>
            </div>
          </div>
        )}
        <div className="col-12 mt-2">
          {errors.other && (
            <div className="alert alert-danger text-center">{errors.other}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
