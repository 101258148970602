import React from "react";
import dataService from "../../../../../helpers/dataService";

const useStats = () => {
  const getStat = ({ statName, search }) => {
    return new Promise((resolve, reject) => {
      dataService.post(`statistics/${statName}`, search, resolve, reject);
    });
  };

  return {
    getStat,
  };
};

export default useStats;
