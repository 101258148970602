import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { AwesomeButtonProgress } from "react-awesome-button";
import "react-awesome-button/dist/themes/theme-blue.css";
import "../../theme/awb.scss";
const styles = {
  transition: "1s",
};

const SaveBtn = ({
  save = () => {},
  isSaving,
  isSaved,
  disabled = false,
  text = "Enregistrer",
  type = "primary",
  className = "",
  typeBtn = "button",
  loaderColor = "#ffffff",
}) => {
  const [isLoading, setIsLoading] = useState(isSaving);
  const clickHandle = (e) => {
    if (isLoading) return false;
    setIsLoading(true);
    save(e);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <button
      className="btn btn-primary d-center"
      onClick={clickHandle}
      disabled={disabled || isSaving || isLoading}
      style={{ height: 50 }}
      type={typeBtn}
    >
      {isSaving || isLoading ? <Loader color={loaderColor} size={15} /> : text}
    </button>
  );
};

export default SaveBtn;
