import React from "react";
import "./CtaBox.scss";
import Colors from "../../../../theme/variables.scss";
import { Link } from "react-router-dom";

const CtaBox = ({
  title,
  url,
  icon,
  buttonText,
  buttonIconRight,
  ...props
}) => {
  return (
    <Link
      to={url}
      className="btn bg-white shadow-sm d-center flex-column py-3 w-100 text-primary h-100"
      {...props}
    >
      <i className={`fa mb-1 fa-${icon} fa-3x`} />
      <div></div>
      <div className="pt-2 pb-1">
        <strong>{title}</strong>
      </div>
    </Link>
  );
};

export default CtaBox;
