import React from "react";
import Control from "../../../../common/Controls/Control";
import useStoreState from "../../Context/Store/useStoreState";

const CommentBlock = () => {
  const { items, items_actions } = useStoreState();

  const updateComment = (e) => {
    items_actions.setItem("order", {
      ...items.order,
      order: { ...items.order.order, comment: e.target.value },
    });
  };

  return (
    <div>
      <Control
        label="Commentaires"
        type="textarea"
        value={items.order.order?.comment || ""}
        change={updateComment}
      />
    </div>
  );
};

export default CommentBlock;
