import { toast } from "react-toastify";
import axios from "axios";
import { DateTime } from "luxon";
const tools = {
  findIn: (inTheArray, theValue, theField) => {
    var returnValue = {};
    if (inTheArray.length && theField) {
      returnValue = inTheArray.find((en) => en[theField] == theValue) || {};
    }
    return returnValue;
  },
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },
  copyText: (ref) => {
    ref.current.select();
    ref.current.setSelectionRange(0, 99999);
    document.execCommand("copy");
  },
  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },
  getPct: (num1, num2) => {
    return tools.round((num1 * 100) / num2);
  },
  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: "GET_ERRORS", payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);

    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
  },

  isParentRoleActive(roleKey, roleConfig, dependsOn) {
    if (!dependsOn) {
      return true;
    }
    var isActive = true;
    dependsOn.forEach((d) => {
      if (!roleConfig[d][roleKey]) {
        isActive = false;
      }
    });
    return isActive;
  },
  validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  },
  validateTel(tel) {
    return tel.match(/^\d{10}$/);
  },
  getFullName(user) {
    return (
      (user.gender == 1 ? "Mr " : user.gender == 2 ? "Mme " : "") +
      user.firstname +
      " " +
      user.lastname
    );
  },
  getFullAddress(addr) {
    return (
      addr.address +
      " - " +
      addr.location.postalCode +
      ", " +
      addr.location.name
    );
  },
  getWeekdays() {
    return [
      { id: 1, name: "Lundi" },
      { id: 2, name: "Mardi" },
      { id: 3, name: "Mercredi" },
      { id: 4, name: "Jeudi" },
      { id: 5, name: "Vendredi" },
      { id: 6, name: "Samedi" },
      { id: 7, name: "Dimanche" },
    ];
  },
  getPriceNetTransformed(priceWeight, weight, ratioTransformed, ratioCooked) {
    var prixKgCru = priceWeight / weight;
    var lossPercents = ratioTransformed + ratioCooked;
    var prixKgNet = prixKgCru - (prixKgCru * lossPercents) / 100;
    return Math.round(prixKgNet * 100) / 100;
  },
  round(num) {
    if (isNaN(parseFloat(num))) return "";
    return Math.round(parseFloat(num) * 100) / 100;
  },
  roundInt(num) {
    if (isNaN(parseFloat(num))) return "";
    return Math.round(parseFloat(num));
  },
  printDiv(element) {
    var printContents = document.querySelector(element).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  },
  getNumOfWeek: (beginAt, nbWeeksByCycle = 6) => {
    let week1Original = DateTime.fromFormat("2020-11-23", "yyyy-MM-dd");
    beginAt = DateTime.fromISO(beginAt);

    let nbWeeks = Math.round(
      beginAt.diff(week1Original, "weeks").toObject().weeks
    );

    return (nbWeeks % nbWeeksByCycle) + 1;
  },
  orderDiff: (
    orderSlots,
    newSlots,
    consumptionProfiles,
    contracts,
    slotRefs,
    days,
    moments
  ) => {
    var diffs = [];
    newSlots.forEach((ns) => {
      var ref = orderSlots.find(
        (o) =>
          o.day == ns.day &&
          o.moment == ns.moment &&
          o.consumptionProfile == ns.consumptionProfile
      );

      if (ref.qty != ns.qty) {
        diffs.push({ ...ref, oldQty: ref.qty, newQty: ns.qty });
      }
    });

    diffs = diffs.map((diff) => {
      return {
        ...diff,
        csObj: consumptionProfiles.find(
          (cs) => cs.id == diff.consumptionProfile
        ),
        contract: contracts.find(
          (c) =>
            c.id ==
            consumptionProfiles.find((cs) => cs.id == diff.consumptionProfile)
              .clientContract
        ),
      };
    });

    diffs = diffs.map((diff) => {
      var dishes = slotRefs.filter((sr) => diff.slotRefs.includes(sr.id));

      return `${days.find((d) => d.id == diff.day).name} ${
        moments.find((m) => m.id == diff.moment).name
      }, Contrat ${diff.contract.name}, Profil de consommation ${
        diff.csObj.name
      }, ${dishes.map((d) => d.technicalSheet.name).join(",")}, quantité ${
        diff.oldQty
      } -> ${diff.newQty}`;
    });
    console.log("DIFFS", diffs);
    return diffs;
  },
  getLockedDays: (selectedDate, prodSiteDaysOff) => {
    console.log("prodSiteDaysOff", prodSiteDaysOff);
    var daysResult = {};
    for (var i = 0; i <= 6; i++) {
      var diffDayBetweenTodayAndSelectedDate = DateTime.fromISO(selectedDate)
        .plus({ days: i })
        .diff(DateTime.local(), ["days"])
        .toObject().days;

      if (diffDayBetweenTodayAndSelectedDate > 0) {
        var tempDiff = diffDayBetweenTodayAndSelectedDate;
        for (var y = 0; y < diffDayBetweenTodayAndSelectedDate; y++) {
          var nextDay = DateTime.local().plus({ days: y }).weekday;

          if (prodSiteDaysOff.includes(nextDay)) {
            tempDiff -= 1;
          }
        }
        diffDayBetweenTodayAndSelectedDate = tempDiff;
      }
      daysResult[i + 1] = diffDayBetweenTodayAndSelectedDate;
    }
    return daysResult;
  },
  transformQtyKg: (qty, unit) => {
    if (unit == 1) {
      qty = Math.round((qty / 1000) * 100) / 100 + " Kg";
    }
    if (unit == 2) {
      qty = qty + " Pièces";
    }
    if (unit == 3) {
      qty = qty + " Litres";
    }

    return qty;
  },

  print: (printOrientation) => {
    var css = `@page { size: ${printOrientation}; } html,body,#root {background:white !important;}`,
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);

    window.print();
  },
  getSmallUnit: (unit) => {
    return unit == 1 ? "G" : unit == 2 ? "L" : unit == 3 ? "Pièces" : "";
  },

  getMomentsFromSites: (sites) => {
    var moments = [];
    sites.forEach((site) => {
      site.moments.forEach((moment) => {
        if (!moments.includes(moment.moment)) {
          moments.push(moment.moment);
        }
      });
    });

    return moments.sort((a, b) => (a > b ? 1 : -1));
  },

  getContractsOfMoments: (sites, moments) => {
    var results = [];
    moments.map((moment) => {
      var momentContracts = [];
      sites.forEach((site) => {
        var momentsOfSite = site.moments.filter((ss) => ss.moment == moment);
        momentsOfSite.forEach((ms) => {
          var diffContracts = ms.contracts.filter(
            (sc) => !momentContracts.find((c) => c.id == sc.id)
          );
          momentContracts = [...momentContracts, ...diffContracts];
        });
      });
      results.push(momentContracts);
    });
    return results;
  },

  getQtyPriceRecap: (datas) => {
    var sites = [];
    datas.forEach((date) => {
      date.sites.forEach((site) => {
        if (!sites.find((s) => s.id == site.id)) {
          sites.push({
            id: site.id,
            name: site.name,
            contracts: [],
            qty: 0,
            price: 0,
          });
        }
        if (site.qty) {
          sites.find((s) => s.id == site.id).qty += site.qty;
        }
        if (site.price) {
          sites.find((s) => s.id == site.id).price += site.price;
        }

        site.moments.forEach((moment) => {
          moment.contracts.forEach((contract) => {
            if (
              !sites
                .find((s) => s.id == site.id)
                .contracts.find((c) => c.id == contract.id)
            ) {
              sites
                .find((s) => s.id == site.id)
                .contracts.push({
                  ...contract,
                  qty: 0,
                });
            }
            sites
              .find((s) => s.id == site.id)
              .contracts.find((c) => c.id == contract.id).qty += contract.qty;
          });
        });
      });
    });
    return sites;
  },

  getQtyByContract: (datas) => {
    var contracts = [];
    datas.forEach((date) => {
      date.sites.forEach((site) => {
        site.moments.forEach((moment) => {
          moment.contracts.forEach((contract) => {
            if (!contracts.find((c) => c.id == contract.id)) {
              contracts.push({
                ...contract,
                qty: 0,
              });
            }
            contracts.find((c) => c.id == contract.id).qty += contract.qty;
          });
        });
      });
    });
    return contracts;
  },
  formatFollowingPattern: (value = "", separation = [], separator = "") => {
    if (!value) return "";
    var nirconcat = value.split(separator).join("");

    var newnir = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < nirconcat.length) {
        newnir += nirconcat.substring(curr, ns);
        var newnirsp = newnir.split(separator);
        if (
          newnirsp[newnirsp.length - 1].length == separation[key] &&
          newnirsp.length < separation.length
        ) {
          newnir += separator;
        }
      }

      curr = ns;
    }
    if (newnir.substr(newnir.length - separator.length) == separator) {
      newnir = newnir.substring(0, newnir.length - separator.length);
    }
    return newnir;
  },
  formatPhone: (phone) => {
    if (!phone) return "";
    let normalVal = phone.split(".").join("").split(" ").join("");
    return tools.formatFollowingPattern(normalVal, [4, 2, 2, 2], " ");
  },
  areDatesEqual: (d1, d2) => {
    var day1 = DateTime.fromISO(d1).day;
    var month1 = DateTime.fromISO(d1).month;
    var year1 = DateTime.fromISO(d1).year;
    var day2 = DateTime.fromISO(d2).day;
    var month2 = DateTime.fromISO(d2).month;
    var year2 = DateTime.fromISO(d2).year;
    console.log(`${day1}/${month1}/${year1} == ${day2}/${month2}/${year2}`);
    return day1 == day2 && month1 == month2 && year1 == year2;
  },
  formatDate: (date, format = "") => {
    return date ? DateTime.fromISO(date).toFormat(format || "dd/MM/yyyy") : "";
  },
  holyday: (an) => {
    var JourAn = new Date(an, "00", "01");
    var FeteTravail = new Date(an, "04", "01");
    var Victoire1945 = new Date(an, "04", "08");
    var FeteNationale = new Date(an, "06", "14");
    var Assomption = new Date(an, "07", "15");
    var Toussaint = new Date(an, "10", "01");
    var Armistice = new Date(an, "10", "11");
    var Noel = new Date(an, "11", "25");
    var SaintEtienne = new Date(an, "11", "26");

    var G = an % 19;
    var C = Math.floor(an / 100);
    var H =
      (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) %
      30;
    var I =
      H -
      Math.floor(H / 28) *
        (1 -
          Math.floor(H / 28) *
            Math.floor(29 / (H + 1)) *
            Math.floor((21 - G) / 11));
    var J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
    var L = I - J;
    var MoisPaques = 3 + Math.floor((L + 40) / 44);
    var JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4);
    var Paques = new Date(an, MoisPaques - 1, JourPaques);
    var VendrediSaint = new Date(an, MoisPaques - 1, JourPaques - 2);
    var LundiPaques = new Date(an, MoisPaques - 1, JourPaques + 1);
    var Ascension = new Date(an, MoisPaques - 1, JourPaques + 39);
    var Pentecote = new Date(an, MoisPaques - 1, JourPaques + 49);
    var LundiPentecote = new Date(an, MoisPaques - 1, JourPaques + 50);

    return new Array(
      JourAn,
      VendrediSaint,
      Paques,
      LundiPaques,
      FeteTravail,
      Victoire1945,
      Ascension,
      Pentecote,
      LundiPentecote,
      FeteNationale,
      Assomption,
      Toussaint,
      Armistice,
      Noel,
      SaintEtienne
    );
  },
  isHolyday: (day) => {
    let isHoliday = false;
    tools.holyday(day.toFormat("yyyy")).forEach((holy) => {
      const holyday = DateTime.fromJSDate(holy)
        .startOf("day")
        .toFormat("dd/MM/yyyy");
      const thisday = day.startOf("day").toFormat("dd/MM/yyyy");

      if (holyday == thisday) {
        isHoliday = true;
      }
    });
    return isHoliday;
  },
  daysBetweenDates: (date1, date2) => {
    var dt1 = DateTime.fromISO(date1);
    var dt2 = DateTime.fromISO(date2);

    var result = dt1.diff(dt2, "days").toObject();

    console.log(result.days);
    return result.days;
  },
  getFirstLetters: (text, nbLetters) => {
    if (!text) return "";
    return text.substr(0, nbLetters);
  },
};

export default tools;
