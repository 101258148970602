import React from "react";

const Footer = () => {
  return (
    <div className="row py-4 ">
      <div className="col-lg-2 col-md-4">
        <address style={{ color: "rgb(66, 82, 110)" }}>
          Atelier D19 ZAC Mascareignes 2<br />
          22 rue Pierre Brossolette
          <br />
          97420 Le Port
        </address>
      </div>
      <div className="col-lg-3 col-md-4 align-self-center">
        <a
          href="tel:0262251805"
          className="tel text-green"
          style={{ fontSize: 30 }}
        >
          0262 25 18 05
        </a>
      </div>
      <div className="col-lg-4 col-md-4 align-self-center">
        <a
          href="mailto:cuisinecentrale@qualicarry.fr"
          className="text-primary"
          style={{ fontSize: 22 }}
        >
          cuisinecentrale@qualicarry.fr
        </a>
      </div>
      {/* <div className="col-lg-3 col-md-12 align-self-center">
        <a
          href="/mentions-legales"
          style={{
            textDecoration: "underline",
            color: "rgb(158, 171, 196)",
          }}
        >
          Mentions légales
        </a>
        &nbsp;-&nbsp;
        <a
          href="/</div>conditions-generales-utilisation"
          style={{ textDecoration: "underline", color: "rgb(158, 171, 196)" }}
        >
          CGU
        </a>
      </div> */}
    </div>
  );
};

export default Footer;
