import React, { useEffect, useState } from "react";
import useStoreState from "../useStoreState";
import ClientUserDetail from "./ClientUserDetail";
import ClientUserList from "./ClientUserList";

const UserContainer = () => {
  const { state, items, items_actions } = useStoreState();
  const { users, client } = { ...items };
  const userInitialState = {
    role: "",
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    client: client.id,
    sites: [],
  };
  const isDisabled = true;
  const [showUserForm, setShowUserForm] = useState(false);
  useEffect(() => {
    setShowUserForm(false);
  }, [users]);

  return (
    <div className="row mt-5">
      <div className="col-12">
        <ClientUserList
          disabled={isDisabled}
          setShowUserForm={setShowUserForm}
        />
        {!isDisabled && (
          <>
            <div className="d-flex justify-content-end w-100">
              <button
                className="btn btn-default "
                onClick={() => setShowUserForm(userInitialState)}
              >
                <i className="fa fa-plus mr-2" />
                Ajouter un utilisateur
              </button>
            </div>

            {showUserForm ? <ClientUserDetail user={showUserForm} /> : null}
          </>
        )}
      </div>
    </div>
  );
};

export default UserContainer;
