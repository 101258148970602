import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../helpers/dataService";
import DynamicList from "../DynamicList";
import SaveBtn from "../SaveBtn";

const siteAtom = atom(null);

const SiteCPForm = ({ siteData }) => {
  const [site, setSite] = useAtom(siteAtom);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSite(siteData);
  }, [siteData]);

  const handleChangeSite = (e) => {
    setSite({ ...site, closingPeriods: e.target.value });
  };

  const saveSite = () => {
    setIsSaving(true);
    dataService.patch(
      `sites/${site.id}`,
      site,
      (data) => {
        toast.success("Vos modifications ont bien été enregistrées");
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  return (
    site && (
      <>
        <DynamicList
          label=""
          name="DynamicList"
          change={handleChangeSite}
          fields={[
            {
              label: "Début",
              type: "date",
              name: "start",
              min: DateTime.local().toFormat("yyyy-MM-dd"),
            },
            {
              label: "Fin",
              type: "date",
              name: "end",
              min: DateTime.local().plus({ days: 1 }).toFormat("yyyy-MM-dd"),
            },
          ]}
          value={site.closingPeriods}
          btnLabel="Ajouter une période de fermeture"
          error={errors}
        />
        <div className="d-flex align-items-end justify-content-end">
          <SaveBtn
            save={saveSite}
            isSaving={isSaving}
            text={`Enregistrer ces périodes pour ${site.name}`}
          />
        </div>
      </>
    )
  );
};

export default SiteCPForm;
