import React, { useEffect, useState } from "react";
import Page from "../../../common/layout/Page";
import useStoreState from "../Context/Store/useStoreState";
import ClientMenuPrint from "./ClientMenuPrint";
import OrderFilters from "./OrderFilters/OrderFilters";
import OrderList from "./OrderList/OrderList";

const OrderPage = (props) => {
  const { state, items, items_actions, dispatch } = useStoreState();
  const [showPrintMenu, setShowPrintMenu] = useState(false);

  console.log("state local", state);
  useEffect(() => {
    items_actions.initItems(props.items);
  }, []);

  var displayOrderList =
    items.sites &&
    items.utils?.selectedSite &&
    items.sites.find((s) => s.id == items.utils?.selectedSite);
  var isLoading =
    state.isLoading ||
    state.isLoadingSites ||
    state.isLoadingClients ||
    state.isLoadingOrder;

  useEffect(() => {
    if (state.isInit) {
      items_actions.loadSites();
    }
  }, [state.isInit]);

  useEffect(() => {
    if (items.sites?.length) {
      dispatch({
        type: "UPDATE_SELECTED_CLIENT",
        payload: items.sites[0].client,
      });
    }
  }, [items.sites]);

  return (
    <Page
      title={props.pageTitle}
      isLoading={isLoading}
      back={props.back}
      style={{ maxWidth: "95%" }}
    >
      {state.isInit && (
        <>
          <OrderFilters
            setShowPrintMenu={setShowPrintMenu}
            showPrintMenu={showPrintMenu}
          />
          {displayOrderList ? <OrderList /> : null}
        </>
      )}
    </Page>
  );
};

export default OrderPage;
