import React, { useState } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Controls/Control";
import Axios from "axios";
import { API_URL, REF_BO, PLATFORM } from "../../../config";
import Loader from "../../common/Loader";
import SaveBtn from "../../common/SaveBtn";

const ForgotPassword = ({ handleForgotPasswordClick, captchaToken }) => {
  const [email, setEmail] = useState("");
  const [emailOk, setEmailOk] = useState(false);
  const [errors, setErrors] = useState({});
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  function checkEmail(e) {
    e.preventDefault();
    setCheckingEmail(true);
    Axios.post(API_URL + "auth/renew-password/" + PLATFORM, {
      login: email,
      captchaToken,
    })
      .then((res) => {
        setCheckingEmail(false);
        setEmailSent(true);
      })
      .catch((err) => {
        console.log(err);
        setCheckingEmail(false);

        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  return (
    <form
      onSubmit={checkEmail}
      className="d-flex align-items-center justify-content-start flex-column mx-auto animated zoomIn faster login-form mt-5"
      style={{ maxWidth: 500 }}
    >
      <div className=" text-center">
        <h1 className="my-5 text-secondary main-title">
          MOT DE PASSE OUBLIÉ
          <hr className="w-50 mt-4" />
        </h1>
      </div>
      {emailSent ? (
        <div>
          <p className="text-secondary mb-0 w-100 m-auto text-center">
            Un email vous a été envoyé pour réinitialiser votre mot de passe. Consultez votre boite
            email et cilquez sur le lien qu'il contient.
          </p>
          <button
            onClick={() => handleForgotPasswordClick(false)}
            className="btn btn-link w-100 mt-3"
          >
            <i className="fa fa-chevron-left mr-2"></i>Revenir à la connexion
          </button>
        </div>
      ) : (
        <>
          <p className="mb-4 w-100 text-left">
            Une fois ce formulaire rempli, vous recevrez un email contenant un lien qui vous
            permettra de définir un nouveau mot de passe pour votre compte.
          </p>
          <Control
            label="Entrez votre email"
            type="email"
            className="w-100"
            value={email}
            change={(e) => setEmail(e.target.value)}
            name="email"
            error={errors}
          />
          {checkingEmail ? (
            <Loader className="my-3" withContainer={true} />
          ) : (
            <>
              <div className="w-100 mx-auto d-flex justify-content-between">
                <button
                  onClick={() => handleForgotPasswordClick(false)}
                  style={{ fontSize: 12 }}
                  className="btn btn-link text-secondary p-0"
                >
                  <i className="fa fa-chevron-left mr-2" />
                  Revenir à la connexion
                </button>
                <SaveBtn
                  className="animated zoomIn mt-2"
                  isSaving={checkingEmail}
                  save={checkEmail}
                  text="Recevoir le lien"
                  type="primary"
                  typeBtn="submit"
                />
              </div>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default ForgotPassword;
