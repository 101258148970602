import React, { useEffect, useState } from "react";
import Control from "../../../../../common/Controls/Control";
import useStoreState from "../../../Context/Store/useStoreState";
import ConsumptionProfileItem from "./ConsumptionProfileItem";
import ContractItem from "./ContractItem";
import ContractQty from "./ContractQty";
import DishItem from "./DishItem";

const ContractBlock = ({ day, moment }) => {
  const { state, gState, items, items_actions } = useStoreState();
  const { contracts, slotOrders, consumptionProfiles, slotRefs, consumers } = items.order;

  const [myContracts, setMyContracts] = useState([]);
  const [myConsumptionProfiles, setMyConsumptionProfiles] = useState([]);
  const [mySlots, setMySlots] = useState([]);
  const [myRealSlots, setMyRealSlots] = useState({});
  const [referentConsPro, setReferentConsPro] = useState("");
  const [dlTSLocked, setDlTSLocked] = useState(false);

  useEffect(() => {
    var mySlots = slotOrders.filter(
      (slotOrder) => slotOrder.day == day.id && slotOrder.moment == moment.id
    );

    var myCs = mySlots.map((s) => s.consumptionProfile);
    //console.log("myCs", myCs);
    var allCs = consumptionProfiles.filter((c) => myCs.includes(c.id));
    var allContracts = allCs.map((c) => c.clientContract);
    var filteredContracts = contracts.filter((c) => allContracts.includes(c.id));
    var filteredCs = consumptionProfiles.filter((c) =>
      filteredContracts.find((ct) => ct.id == c.clientContract)
    );
    var filteredSlots = {};
    var realFilteredSlots = {};
    var refCp = "";
    filteredCs.forEach((cs, csk) => {
      if (csk == 0) refCp = cs.id;
      filteredSlots[cs.id] = slotOrders.filter(
        (s) => s.consumptionProfile == cs.id && s.day == day.id && s.moment == moment.id
      );
      filteredSlots[cs.id].forEach((slot, sk) => {
        if (!realFilteredSlots[cs.id]) {
          realFilteredSlots[cs.id] = [];
        }
        realFilteredSlots[cs.id][sk] = slotRefs.filter((sr) => slot.slotRefs.includes(sr.id));
      });
    });
    // if (Object.values(realFilteredSlots).length) {
    //   console.log("realFilteredSlots", realFilteredSlots);
    // }
    setReferentConsPro(refCp);
    setMySlots(filteredSlots);
    setMyRealSlots(realFilteredSlots);
    setMyConsumptionProfiles(filteredCs);
    setMyContracts(filteredContracts);
  }, [slotOrders]);

  const applyStyleToSimilarProfiles = (name) => {
    const profileBoxes = document.querySelectorAll(`[data-name="${name}"]`);
    profileBoxes.forEach((box) => {
      box.style.backgroundColor = "#393839";
      box.style.border = "2px solid black";
      box.style.color = "white";
    });
  };
  const removeStyleToSimilarProfiles = (name) => {
    const profileBoxes = document.querySelectorAll(`[data-name="${name}"]`);
    profileBoxes.forEach((box) => {
      box.style.backgroundColor = "#e2e8ed";
      box.style.border = "0px solid black";
      box.style.color = "black";
    });
  };

  return (
    <div className="w-100">
      {myContracts.map((contract, ck) => {
        var contractConsumptionProfiles = myConsumptionProfiles.filter(
          (c) =>
            c.clientContract == contract.id &&
            slotOrders
              .filter((s) => s.day == day.id && s.moment == moment.id)
              .find((s) => s.consumptionProfile == c.id)
        );
        return (
          <ContractItem
            day={day}
            moment={moment}
            contract={contract}
            key={`contract${day.id}${moment.id}${contract.id}`}
          >
            <div className=" d-flex flex-column flex-fill" style={{ width: "85%" }}>
              {contractConsumptionProfiles.map((cs, csk) => {
                return (
                  <div
                    className={`d-center flex-column h-100  ${
                      csk == contractConsumptionProfiles.length - 1 ? "mb-0" : "mb-2"
                    }`}
                    key={`${contract.id}${cs.id}`}
                  >
                    <ConsumptionProfileItem cs={cs}>
                      <div className="w-100">
                        <div
                          style={{
                            width: "100%",
                            borderTopRightRadius: 5,
                            overflow: "hidden",
                          }}
                          className="d-flex flex-column h-100 justify-content-between"
                        >
                          {mySlots[cs.id].length
                            ? mySlots[cs.id].map((slot, sk) => {
                                var slotOrderOrigin = slotOrders.find(
                                  (c) =>
                                    c.day == day.id &&
                                    c.moment == moment.id &&
                                    c.consumptionProfile == cs.id &&
                                    c.consumer == slot.consumer
                                );
                                const quantity = slotOrderOrigin?.qty || 0;

                                const profileName = consumers.find(
                                  (cons) => cons.id == slot.consumer
                                ).name;
                                return (
                                  <div
                                    className={`d-flex flex-row h-100  cs-order-block ${
                                      sk == mySlots[cs.id].length - 1 ? "mb-0" : "mb-2"
                                    }  ${quantity > 0 ? "active" : ""}`}
                                    onMouseOver={() => applyStyleToSimilarProfiles(profileName)}
                                    onMouseOut={() => removeStyleToSimilarProfiles(profileName)}
                                  >
                                    <span
                                      className={` text-center d-center py-2`}
                                      data-name={profileName}
                                      style={{
                                        width: "20%",
                                        fontSize: 12,
                                        writingMode: "tb-rl",
                                        transform: "rotate(180deg)",
                                        color: "black",
                                      }}
                                    >
                                      {profileName}
                                    </span>
                                    <div className="d-flex flex-column" style={{ width: "80%" }}>
                                      {myRealSlots[cs.id][sk]
                                        .sort((a, b) => (a.order < b.order ? -1 : 1))
                                        .map((sl, slk) => {
                                          // if (
                                          //   csk > 0 &&
                                          //   myRealSlots[referentConsPro][0].find(
                                          //     (sorigin) => sorigin.id == sl.id
                                          //   )
                                          // ) {
                                          //   return null;
                                          // }
                                          return (
                                            <DishItem
                                              sl={sl}
                                              key={`cb${day.id}${moment.id}${slot.id}${sl.id}`}
                                              dlTSLocked={dlTSLocked}
                                              setDlTSLocked={setDlTSLocked}
                                            />
                                          );
                                        })}
                                      <ContractQty
                                        day={day}
                                        moment={moment}
                                        csId={cs.id}
                                        consumerId={slot.consumer}
                                        contractId={contract.id}
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </ConsumptionProfileItem>
                  </div>
                );
              })}
            </div>
          </ContractItem>
        );
      })}
    </div>
  );
};

export default ContractBlock;
