import React from "react";
import itemReducer from "./Context/reducers/itemsReducer";
import ClientPage from "./ClientPage";
import ItemStore from "./Context/ItemStore";
import ClientContainer from "./components/ClientContainer";
import ContractsContainer from "./components/ContractsContainer";
import SitesContainer from "./components/SitesContainer";
import EntityView from "./components/EntityView";
import UserContainer from "./components/UserContainer";
import useStore from "../../../../context/useStore";
import ContactBlock from "./ContactBlock";

const ClientDetail = (props) => {
  const [state, dispatch] = useStore();
  const fetchId = state.auth.user.client;

  const items = [
    {
      name: "client",

      initialState: {
        id: fetchId,
        name: "",
        code: "",
        fixFees: [],
        isArchived: false,
      },
    },
    {
      name: "sites",
      fetchOnLoad: fetchId !== "create" ? true : false,
      paths: {
        get: `sites/mines`,
      },

      initialState: [],
    },
    {
      name: "productionSites",
      fetchOnLoad: true,
      paths: {
        get: `productionsites`,
      },

      initialState: [],
    },
    {
      name: "contracts",
      fetchOnLoad: fetchId !== "create" ? true : false,
      paths: {
        get: `clientcontracts/mines`,
      },

      initialState: [],
    },
    {
      name: "users",
      fetchOnLoad: fetchId !== "create" ? true : false,
      paths: {
        get: `clients/${fetchId}/users`,
      },

      initialState: [],
    },
    {
      name: "slotCodes",
      fetchOnLoad: true,
      paths: {
        get: `slotcodes`,
      },

      initialState: [],
    },
    {
      name: "consumerTypes",
      fetchOnLoad: true,
      paths: {
        get: `consumertypes`,
      },

      initialState: [],
    },
    {
      name: "specificities",
      fetchOnLoad: true,
      paths: {
        get: `specificities`,
      },

      initialState: [],
    },
    {
      name: "textures",
      fetchOnLoad: true,
      paths: {
        get: `textures`,
      },

      initialState: [],
    },
    {
      name: "utils",
      fetchOnLoad: false,

      initialState: {
        clientId: fetchId,
        savingSite: false,
        savingContract: false,
        savingUser: false,
        entitySelected: {
          type: "",
          id: "",
        },
      },
    },
    { name: "errors", initialState: {} },
  ];

  return (
    <ItemStore rootReducer={itemReducer}>
      <ClientPage pageTitle={`Compte client`} back="/clients" items={items}>
        <ContactBlock text="Pour toute demande d'information, contactez Qualicarry." />
        {/* <ClientContainer /> */}
        <UserContainer />

        {fetchId !== "create" && <SitesContainer />}
        <EntityView />
      </ClientPage>
    </ItemStore>
  );
};

export default ClientDetail;
