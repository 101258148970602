import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import tools from "../../../../../helpers/tools";
import BarChart from "../CommonCharts/BarChart";
import { filterSameValues } from "../statTools";
import useStats from "../useStats/useStats";

const DishWeightChart = ({ search }) => {
  const { getStat } = useStats();
  const [hasChanged, setHasChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });

  const fetchData = async () => {
    setIsLoading(true);
    var results = await getStat({ statName: "dishesweight", search }).catch(
      (err) => ({
        errors: err,
      })
    );
    setIsLoading(false);
    if (!results.errors) {
      let filteredSeries = filterSameValues(results);

      setChartData({
        categories: filteredSeries.map((r) =>
          DateTime.fromISO(r.date).toFormat("dd/MM")
        ),
        series: filteredSeries.map((r) => tools.round(r.value / 1000)),
      });
      setHasChanged(false);
    }
  };

  useEffect(() => {
    if (search) {
      setHasChanged(true);
    }
  }, [search]);

  return (
    <div className="custom-card">
      <div className="d-flex justify-content-between align-items-center">
        <strong>Poids alimentaire traité en Kg</strong>
        {hasChanged && (
          <button onClick={fetchData} className="btn btn-default load-stat">
            <i
              className={`fa fa-refresh ${
                isLoading ? "animated rotate infinite" : ""
              }`}
            />
          </button>
        )}
      </div>
      {!hasChanged && (
        <BarChart
          categories={chartData.categories}
          series={chartData.series}
          seriesName="Poids alimentaire traité (Kg)"
        />
      )}
    </div>
  );
};

export default DishWeightChart;
