import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import DynamicList from "../../../common/DynamicList";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn";
import SimpleDynamicList from "../../../common/SimpleDynamicList";
import ColorPicker from "../../Menus/GlobalMenu/GlobalMenuPage/Panels/Slot/DishSlot/ColorPicker";

const SlotCodesDetail = (props) => {
  const [state, dispatch] = useStore();

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [slotCode, setSlotCode] = useState({
    name: "",
    color: "#2A9D8F",
    dishTypes: [],
    productionSites: [],
    moments: [],
    isManual: false,
    days: [1, 2, 3, 4, 5, 6, 7],
  });
  const itemId = props.match.params.id;
  const isEdit = itemId == "create" ? false : true;
  const constants = state.constants.items;
  const [dishTypes, setDishTypes] = useState([]);
  const [productionSites, setProductionSites] = useState([]);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    if (isEdit) {
      dataService.get(
        "slotcodes/" + itemId,
        (datas) => setSlotCode({ ...slotCode, ...datas }),
        (error) => setErrors(error),
        () => setIsLoading(false)
      );
      dataService.get(
        `slotcodes/${itemId}/clientcontracts`,
        setContracts,
        (error) => setErrors(error),
        () => setIsLoading(false)
      );
    }
    dataService.get(
      "dishtypes",
      (datas) => setDishTypes(datas),
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
    dataService.get(
      "productionsites",
      (datas) => setProductionSites(datas),
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  const save = () => {
    setIsLoading(true);
    if (isEdit) {
      dataService.patch(
        "slotcodes/" + itemId,
        slotCode,
        (datas) =>
          toast.success("Référence plan alimentaire enregistrée avec succès"),
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      dataService.post(
        "slotcodes",
        slotCode,
        (datas) => {
          toast.success("Référence plan alimentaire créée avec succès");
          if (props.modal) {
            props.onSave(datas);
          } else {
            window.location.href = "/slotcodes/" + datas.id;
          }
        },
        setErrors,
        () => setIsLoading(false)
      );
    }
  };

  return (
    <Page
      container="container"
      back="/slotcodes"
      title={
        props.modal
          ? ""
          : (isEdit ? "Modification" : "Détail") +
            " de la référence plan alimentaire"
      }
      errors={errors}
      style={props.modal ? { maxWidth: "100%" } : { maxWidth: "80%" }}
    >
      {itemId !== "create" && (
        <div className="row p-3 mb-2 bg-white shadow-sm rounded">
          <div className="col-12">
            <strong>Clients/sites utilisant cette référence</strong>
            <ul className="list-group">
              {contracts.map((c, ck) => (
                <li
                  className="list-group-item border-0 p-0 d-center justify-content-start"
                  key={`con${c.id}`}
                >
                  <i className="text-primary">Client {c.client.name}</i>
                  <i className="fa fa-chevron-right mx-2" />
                  {c.sites.map((s, sk) => (
                    <span
                      className="badge badge-primary mr-1"
                      key={`sit${c.id}${s.id}`}
                    >
                      {s.name}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div
        className={`row ${
          !props.modal ? "p-3 bg-white shadow-sm rounded" : ""
        }`}
      >
        <div className="col-12 col-md-6">
          <Control
            label="Nom"
            name="name"
            type="text"
            value={slotCode.name}
            change={(e) => setSlotCode({ ...slotCode, name: e.target.value })}
            error={errors}
            setErrors={setErrors}
            required={true}
          />

          <SimpleDynamicList
            label="Types de plats"
            name="dishTypes"
            change={(e) =>
              setSlotCode({
                ...slotCode,
                dishTypes: e.target.value,
              })
            }
            field={{
              type: "select",
              datas: dishTypes,
              name: "dishTypes",
              dataIndex: "id",
              dataLabel: "name",
            }}
            value={slotCode.dishTypes}
            btnLabel="Ajouter"
          />
        </div>

        <div className="col-12 col-md-6">
          <SimpleDynamicList
            label="Cuisine"
            name="productionSites"
            change={(e) =>
              setSlotCode({
                ...slotCode,
                productionSites: e.target.value,
              })
            }
            field={{
              type: "select",
              datas: productionSites,
              name: "productionSites",
              dataIndex: "id",
              dataLabel: "name",
            }}
            value={slotCode.productionSites}
            btnLabel="Ajouter"
          />
        </div>

        <div className="col-12 col-md-6">
          <Control
            label="Jours"
            name="days"
            type="checkBtnList"
            value={slotCode.days}
            change={(e) => setSlotCode({ ...slotCode, days: e.target.value })}
            error={errors}
            datas={constants.DAYS}
            btnInline
          />
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Moments"
            name="moments"
            type="checkBtnList"
            value={slotCode.moments}
            change={(e) =>
              setSlotCode({ ...slotCode, moments: e.target.value })
            }
            error={errors}
            datas={constants.CONSUME_TIMES}
            btnInline
          />
        </div>
        <div className="col-12 mt-2">
          <label style={{ minHeight: 0 }}>
            <span className="label-text">Couleur</span>
          </label>
          <ColorPicker
            color={slotCode.color}
            setColor={(color) => setSlotCode({ ...slotCode, color })}
          />
          <Control
            label="Ne pas inclure cet élément dans la génération automatique des nouveaux menus de références"
            name="isManual"
            type="checkbox"
            checked={slotCode.isManual}
            change={(e) =>
              setSlotCode({
                ...slotCode,
                isManual: e.target.checked,
              })
            }
            error={errors}
          />
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-end  w-100">
          <SaveBtn
            save={save}
            isSaving={isLoading}
            text="Enregistrer"
            type="primary"
          />
        </div>
      </div>
    </Page>
  );
};

export default SlotCodesDetail;
