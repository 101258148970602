import React from "react";
import tools from "../../../../helpers/tools";

const BillingTotal = ({ recap }) => {
  return (
    <div className="row mt-3">
      <div className="col-12 mb-2">
        <h1 style={{ fontSize: 20 }}>Total par site</h1>
      </div>
      {recap.map((site) => (
        <div className="col-4 mb-3">
          <div className="custom-card h-100 d-flex flex-column justify-content-between">
            <div>
              <strong className="d-block text-dark">{site.name}</strong>
              <div>
                {site.contracts.map((contract) => (
                  <div className="d-flex justify-content-between border-bottom">
                    <div>{contract.name}</div>
                    <div>{contract.qty}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-2">
              <div className="d-flex justify-content-between text-secondary font-weight-bold ">
                <div>Quantité totale</div>
                <div>{site.qty}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BillingTotal;
