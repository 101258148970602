import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";

const NowTime = () => {
  const [timer, setTimer] = useState(
    DateTime.local().toFormat("dd/MM/yyyy HH:mm")
  );

  useEffect(() => {
    setInterval(() => {
      setTimer(DateTime.local().toFormat("dd/MM/yyyy HH:mm"));
    }, 10000);
  }, []);

  return (
    <div className="d-flex justify-content-end w-100 only-print only-print-d-block">
      Imprimé le : {timer}
    </div>
  );
};

export default NowTime;
