import React, { useEffect, useState, useRef } from "react";
import LabelContainer from "../LabelContainer";
import ControlTools from "../ControlTools";
import PasswordControl from "./PasswordControl";
import EmptyButton from "./EmptyButton";

const PasswordInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value = "",
  change = () => {},
  placeholder = "",
  minLength = 6,
  minUppercase = 1,
  minLowercase = 3,
  minNumber = 1,
  minSpecial = 0,
  passwordValidationStyle = {},
  setCanSave = () => {},
  showValidation = true,
  ...props
}) => {
  const { name } = props;
  const inputRef = useRef(null);
  const pwRef = useRef(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [passwordCompletion, setPasswordCompletion] = useState({
    icon: "lock",
    isComplete: false,
  });

  const valueChange = (e) => {
    var filteredValue = e.target.value;

    let changeEvent = {
      target: {
        name: name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  useEffect(() => {
    setCanSave(passwordCompletion.isComplete);
  }, [passwordCompletion]);

  return (
    <LabelContainer {...props} validIcon={passwordCompletion.icon} value={value}>
      <input
        ref={inputRef}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        style={inputStyle}
        className={"form-control " + inputClassName}
        type="password"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? name + "-" + k : id ? id : name}
        name={name}
        value={value}
        onChange={valueChange}
        placeholder={placeholder}
      />
      <EmptyButton change={valueChange} name={props.name} targetInput={inputRef} value={value} />
      {showValidation && (
        <div
          className="password-validation-container w-100"
          ref={pwRef}
          style={{
            height: inputFocused ? "100px" : "0px",
            overflow: "hidden",
            transition: "height 300ms",
          }}
        >
          <PasswordControl
            style={passwordValidationStyle}
            password={value}
            minLength={minLength}
            minUppercase={minUppercase}
            minLowercase={minLowercase}
            minSpecial={minSpecial}
            minNumber={minNumber}
            setCanSave={setPasswordCompletion}
          />
        </div>
      )}
    </LabelContainer>
  );
};

export default PasswordInput;
