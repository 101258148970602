import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";
import SaveBtn from "../../../common/SaveBtn";

const Filters = ({ search, setSearch, getDatas, isLoading }) => {
  // const [clients, setClients] = useState([]);

  // useEffect(() => {
  //   getClients();
  // }, []);
  // const getClients = () => {
  //   dataService.get("clients", setClients);
  // };

  return (
    <div className="row mb-3">
      <div className="col-md-4 col-12">
        <Control
          withNav
          label="Entre le"
          type="date"
          value={search.beginAt}
          change={(e) => setSearch({ ...search, beginAt: e.target.value })}
        />
      </div>
      <div className="col-md-4 col-12">
        <Control
          withNav
          label="Et le"
          type="date"
          value={search.endAt}
          change={(e) => setSearch({ ...search, endAt: e.target.value })}
        />
      </div>
      <div className="col-md-4 col-12 justify-content-start align-items-end d-flex noprint">
        <button className="btn btn-secondary btn-sm mb-1" onClick={getDatas}>
          {isLoading ? <Loader color="white" /> : "Afficher"}
        </button>
      </div>
    </div>
  );
};

export default Filters;
