import React, { useEffect } from "react";
import { sidePanelActions } from "../../../../context/actions/sidePanelActions";
import dataService from "../../../../helpers/dataService";
import useApiDatas from "../../../common/Hooks/useApiDatas";
import Loader from "../../../common/Loader";
import RecipeTemplate from "../TechnicalSheetList/RecipeTemplate";

const PrintTechnicalSheetStandalone = ({ technicalSheetId, setIsLoading }) => {
  const {
    state,
    dispatch,
    constants,
    errors,
    setErrors,
    isLoading,
    searchDatas,
    setSearchDatas,
    nbPages,
    setNbPages,
    getSearchDatas,
    deleteData,
    isInit,
    search,
    setSearch,
    otherDatas,
    setOtherDatas,
    nbResults,
    item,
  } = useApiDatas({
    itemId: technicalSheetId,
    itemPaths: {
      get: `technicalsheets/${technicalSheetId}`,
    },

    dependenciesPath: [
      "specificities",
      "consumertypes",
      "textures",
      "ingredients",
      "dishtypes",
    ],
  });
  const spActions = sidePanelActions(state, dispatch);
  const { specificities, consumertypes, textures, ingredients, dishtypes } =
    otherDatas;

  useEffect(() => {
    if (isInit) {
      printFt(item);
    } else {
      setIsLoading(true);
      spActions.updateSidePanel({ isOpen: false, content: null });
    }
  }, [isInit]);

  const printFt = (ft) => {
    console.log(ft);

    dataService.post(
      "technicalsheets/" + ft.id + "/recipes",
      { multiplicator: 10 },
      (recipes) => {
        console.log("recipes", recipes);

        spActions.updateSidePanel({
          isOpen: true,
          content: (
            <>
              <div>
                <strong>Fiche technique : {item.name}</strong>
              </div>
              <RecipeTemplate
                params={{
                  recipes: textures
                    .map((t) => ({
                      texture: t,
                      recipeData:
                        recipes[consumertypes.find((cs) => cs.isReferent).id][
                          t.id
                        ],
                      dishtypes,
                      ingredients,
                      multiplicator: 10,
                    }))
                    .filter((t) => t.texture.isReferent),
                  consumerType: consumertypes.find((cs) => cs.isReferent),
                  technicalSheet: ft,
                  specificities: specificities,
                }}
              />
            </>
          ),
        });
      },
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return isInit ? null : (
    <div
      style={{
        position: "absolute",
        top: 2,
        right: 2,
      }}
    >
      <Loader size={10} color={"white"} />
    </div>
  );
};

export default PrintTechnicalSheetStandalone;
