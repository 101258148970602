import React from "react";
import SaveBtn from "../../../../common/SaveBtn";
import useGlobalState from "../../Context/Store/ItemGlobalState";
import useStoreState from "../../Context/Store/useStoreState";
import OrderPrint from "../OrderPrint/OrderPrint";

const SaveOrderButton = ({ isDisabled }) => {
  const { state, items, items_actions, mActions, gState } = useStoreState();
  const { sites, refSlotOrders, order } = items;
  const { selectedSite, selectedClient, selectedDate } = items.utils;

  const printOrder = () =>
    mActions.updateModal({
      isOpen: true,
      content: (
        <OrderPrint
          order={items}
          DAYS={gState.constants.items.DAYS.filter((day) =>
            sites.find((s) => s.id == selectedSite).activeDays.includes(day.id)
          )}
          MOMENTS={gState.constants.items.CONSUME_TIMES.filter((mm) =>
            refSlotOrders.find((r) => r.moment == mm.id)
          )}
        />
      ),
    });

  return (
    <div
      style={{
        position: "fixed",
        bottom: 20,
        right: 20,
        zIndex: "999",
      }}
      className="custom-card flex-column d-flex bg-white shadow"
    >
      {order.slotRefs[0] && (
        <>
          <button className="btn btn-link pt-0" onClick={items_actions.resetQty}>
            <i className="fa fa-refresh mr-2" />
            Remettre toutes les quantités à 0
          </button>
          <a
            target="_BLANK"
            className="btn btn-link w-100"
            href={`/orders/print/${order.slotRefs[0].menuRef}/${selectedDate}/${selectedClient}/${selectedSite}`}
          >
            <i className={`fa fa-print mr-2`} />
            Imprimer le menu
          </a>
        </>
      )}
      <button className="btn btn-link pt-0" onClick={printOrder}>
        <i className="fa fa-print mr-2" />
        Imprimer la commande
      </button>
      {isDisabled ? (
        <span className="badge badge-default">aucune modification possible</span>
      ) : (
        <SaveBtn
          save={items_actions.saveOrder}
          text="Enregistrer la commande"
          isSaving={state.isLoadingOrder}
        />
      )}
    </div>
  );
};

export default SaveOrderButton;
