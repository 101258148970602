import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Table from "../../common/Table/Table";
import { DateTime } from "luxon";
import dataService from "../../../helpers/dataService";
import baseListController from "./baseListController";
import BaseListFilters from "./BaseListFilters";
import { Link } from "react-router-dom";
import Pagination from "../../common/Pagination";

const BaseList = () => {
  const [state, dispatch] = useStore();
  const [search, setSearch] = useState({
    searchString: "",
    page: 1,
  });
  const [errors, setErrors] = useState({});
  const [datas, setDatas] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nbPages, setNbPages] = useState(10);

  useEffect(() => {
    if (state.constants.items && search) {
      getDatas();
    }
  }, [state.constants.items, search]);

  useEffect(() => {
    if (isLoading) setErrors({});
  }, [isLoading]);

  const getDatas = () => {
    setIsLoading(true);
    setDatas(false);
    const onDataSuccess = (datas) => {
      setDatas(baseListController.formatData(datas));
    };
    const onDataFinish = () => {
      setIsLoading(false);
    };
    dataService.get("posts", onDataSuccess, setErrors, onDataFinish);
  };
  const deleteData = (id) => {
    setIsLoading(true);
    const onRemoveSuccess = (datas) => {
      getDatas();
    };
    const onRemoveFinish = () => {
      setIsLoading(false);
    };
    dataService.remove("posts", id, onRemoveSuccess, setErrors, onRemoveFinish);
  };

  return (
    <Page
      title="Exemple de liste"
      errors={errors}
      back={"/"}
      isLoading={isLoading}
    >
      <div className="row">
        <BaseListFilters
          search={search}
          setSearch={setSearch}
          errors={errors}
        />
      </div>
      <div className="row ">
        <div className="col-12">
          <Table
            exportOptions={{
              excludedFields: ["Actions"],
            }}
            fields={[
              { name: "Id", path: "id" },
              { name: "Titre", path: "title" },
              {
                name: "Actions",
                path: "id",
                render: (el) => (
                  <>
                    <Link
                      to={`/list/${el}`}
                      className="btn btn-default d-center d-inline-flex"
                    >
                      <i className="fa fa-edit" />
                    </Link>
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteData(el)}
                    >
                      <i className="fa fa-times" />
                    </button>
                  </>
                ),
              },
            ]}
            datas={datas}
          />
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={(p) => setSearch({ ...search, page: p })}
          />
        </div>
      </div>
    </Page>
  );
};

export default BaseList;
