import React from "react";
import Control from "./Controls/Control";

const SimpleDynamicList = ({
  label,
  uniqueKey,
  name,
  field,
  value,
  btnLabel,
  change,
  className = "",
}) => {
  function addItem() {
    value.push("");
    change({ target: { name: name, value: value } });
  }

  function changeItem(e) {
    value[e.target.k] = e.target.value;
    change({ target: { name: name, value: value } });
  }
  function removeItem(kk) {
    value = value.filter((i, k) => k !== kk);
    change({ target: { name: name, value: value } });
  }

  return (
    <div className={`form-group ${className}`}>
      <label>
        <span className="label-text">{label}</span>
        <ul className="list-group list-unstyled">
          {value.map((it, k) => (
            <li key={"dyn" + name + uniqueKey + "_" + k}>
              <div className="d-flex align-items-baseline ">
                <Control
                  className={"mr-0 "}
                  k={k}
                  labelClassname="mb-0 mt-0 mr-2 text-black-50"
                  inputStyle={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  label={field.label}
                  name={field.name}
                  type={field.type}
                  value={it}
                  datas={field.datas}
                  change={changeItem}
                  suffix={field.suffix ? field.suffix : null}
                  margin={field.margin ? field.margin : ""}
                />

                <div
                  style={{
                    height: 40,
                  }}
                  className="d-flex align-items-center px-2"
                >
                  <button
                    type="button"
                    className="btn btn-default bg-white btn-sm shadow-none"
                    onClick={() => removeItem(k)}
                  >
                    <i className="fa fa-times text-danger" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </label>
      {btnLabel && (
        <div className="d-flex justify-content-start">
          <button
            className="btn btn-default btn-sm"
            type="button"
            onClick={addItem}
          >
            <i className="fa fa-plus mr-2" />
            {btnLabel}
          </button>
        </div>
      )}
    </div>
  );
};

export default SimpleDynamicList;
