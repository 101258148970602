import React, { useRef } from "react";
import LabelContainer from "../LabelContainer";
import EmptyButton from "./EmptyButton";

const TelInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  separator = " ",
  separation = [2, 2, 2, 2, 2],
  ...props
}) => {
  const inputRef = useRef(null);
  const valueChange = (e) => {
    let filteredValue = e.target.value
      .split(separator)
      .join("")
      .substring(0, 10);

    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  const formatValue = (value) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    var valueConcat = value.split(separator).join("");

    var newValue = "";
    var curr = 0;
    for (var key in separation) {
      var ns = separation[key] + curr;
      if (curr < valueConcat.length) {
        newValue += valueConcat.substring(curr, ns);
        var newValuesp = newValue.split(separator);
        if (
          newValuesp[newValuesp.length - 1].length == separation[key] &&
          newValuesp.length < separation.length
        ) {
          newValue += separator;
        }
      }

      curr = ns;
    }
    if (newValue.substr(newValue.length - separator.length) == separator) {
      newValue = newValue.substring(0, newValue.length - separator.length);
    }

    return newValue;
  };

  return (
    <LabelContainer {...props} value={value}>
      <input
        ref={inputRef}
        autoFocus={autoFocus}
        autoComplete={autocomplete}
        style={inputStyle}
        className={"form-control " + inputClassName}
        pattern="[0-9]*"
        type="tel"
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={formatValue(value)}
        onChange={valueChange}
        placeholder={placeholder}
      />
      <EmptyButton
        change={valueChange}
        name={props.name}
        targetInput={inputRef}
        value={value}
      />
    </LabelContainer>
  );
};

export default TelInput;
