import tools from "../../helpers/tools";



const initialState = {
  isAuthenticated: false,
  user: {},
  isLoading: false,
  productionSiteSelected: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "":
      return {
        ...state,
        isAuthenticated: !tools.isEmpty(action.payload),
        user: {},
        isLoading: action.payload,
      };
    case "SET_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: !tools.isEmpty(action.payload),
        user: action.payload,
        isLoading: false,
      };
    case "CLEAR_CURRENT_USER":
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    case "SELECT_PRODUCTION_SITE":
      return {
        ...state,
        productionSiteSelected: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
