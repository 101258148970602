import React, { useEffect } from "react";
import Control from "../../../../common/Controls/Control";
import DateSelector from "./DateSelector";
import useStoreState from "../../Context/Store/useStoreState";
import { DateTime } from "luxon";
import tools from "../../../../../helpers/tools";
import UpdateHistory from "./UpdateHistory";
import HistoryPrint from "./HistoryPrint";
import { Link } from "react-router-dom";

const OrderFilters = ({ setShowPrintMenu, showPrintMenu }) => {
  const { state, items, items_actions, spActions, mActions } = useStoreState();

  const { selectedClient, selectedSite, selectedDate } = items.utils;
  const { clients, sites, productionSites, order } = items;

  useEffect(() => {
    if (selectedClient && !sites.length) {
      //items_actions.loadSites();
    }
    if (
      selectedClient &&
      selectedSite &&
      selectedDate &&
      sites.length &&
      productionSites.length
    ) {
      var prodSiteDaysOff = productionSites.find(
        (p) => p.id == sites.find((s) => s.id == selectedSite).productionSite
      ).productionDaysOff;
      var lockedDays = tools.getLockedDays(selectedDate, prodSiteDaysOff);
      tools.saveState("orders", "search", {
        selectedClient,
        selectedSite,
        selectedDate,
      });
      console.log(selectedClient, selectedSite, selectedDate);
      items_actions.loadOrder(lockedDays);
    }
  }, [selectedClient, selectedSite, selectedDate, productionSites, sites]);

  const openHistory = () =>
    spActions.updateSidePanel({
      isOpen: true,
      content: <UpdateHistory history={state.items.order.order.historics} />,
    });

  const historyPrint = () => {
    mActions.updateModal({
      isOpen: true,
      content: <HistoryPrint items={items} />,
    });
  };

  return (
    <div className="row custom-card justify-content-start mb-3 mx-0">
      <div className="col-12 col-md-4 d-flex flex-column justify-content-start align-items-start pl-0">
        <DateSelector
          text="Semaine du :"
          beginAt={selectedDate}
          disabled={false}
          change={(e) => items_actions.setSelectedDate(e.target.value)}
        />

        <div className="text-center pl-2 text-primary mt-2">
          {/* <div>du {DateTime.fromISO(selectedDate).toFormat("dd/MM/yyyy")}</div> */}
          <div>
            au{" "}
            {DateTime.fromISO(selectedDate)
              .endOf("week")
              .toFormat("dd/MM/yyyy")}
          </div>
        </div>
      </div>
      {/* <div className="col-12 col-md-4">
        <Control
          label="Client"
          type="select"
          value={selectedClient}
          datas={clients.sort((a, b) => (a.name > b.name ? 1 : -1))}
          change={(e) => items_actions.setSelectedClient(e.target.value)}
        />
      </div> */}
      <div className="col-12 col-md-4">
        <Control
          label="Site"
          type="select"
          value={selectedSite}
          datas={sites}
          change={(e) => items_actions.setSelectedSite(e.target.value)}
        />
        {/* {state.items.order.order && (
          <div className="mt-3 d-flex flex-column justify-content-end">
            <button className="btn btn-default" onClick={openHistory}>
              Voir l'historique des modifications
            </button>
            <button className="btn btn-link" onClick={historyPrint}>
              <i className="fa fa-print mr-2" />
              Imprimer l'historique
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default OrderFilters;
