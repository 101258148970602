import React, { useEffect, useState } from "react";
import SaveBtn from "../../../../common/SaveBtn";
import ClientContractForm from "../../../ClientContracts/ClientContractDetail/ClientContractForm";
import SiteForm from "../../../Sites/SiteDetail/SiteForm";
import useStoreState from "../useStoreState";

const EntityView = (props) => {
  const { state, items, items_actions } = useStoreState();
  const { utils, sites, productionSites, slotCodes, contracts } = { ...items };
  const { entitySelected } = utils;

  const siteParams = {
    client: utils.clientId,
    productionSite: "",
    name: "",
    address: "",
    postalCode: "",
    city: "",
    phone: "",
    closingPeriods: [],
    activeDays: [1, 2, 3, 4, 5],
    conditioningMode: "",
    allotingMode: "",
    isArchived: false,
  };
  const contractParams = {
    client: utils.clientId,
    name: "",
    productionSite: "",
    sites: [],
    slotCodes: [],
    price: 0,
    priceHistorics: [],
  };

  const [selectedSite, setSelectedSite] = useState(false);
  const [selectedContract, setSelectedContract] = useState(false);

  useEffect(() => {
    if (entitySelected.type == "site") {
      if (entitySelected.id.length) {
        setSelectedSite(sites.find((s) => s.id == entitySelected.id));
      } else {
        setSelectedSite(siteParams);
      }
    }
    if (entitySelected.type == "contract") {
      if (entitySelected.id.length) {
        setSelectedContract(contracts.find((s) => s.id == entitySelected.id));
      } else {
        setSelectedContract(contractParams);
      }
    }
  }, [entitySelected]);

  return entitySelected.type == "site" && selectedSite ? (
    <>
      {/* <SiteForm
        site={selectedSite}
        setSite={setSelectedSite}
        productionSites={productionSites}
      /> */}
    </>
  ) : entitySelected.type == "contract" && selectedContract ? (
    <>
      <ClientContractForm
        clientContract={selectedContract}
        setClientContract={setSelectedContract}
      />
      {selectedContract.productionSite.length ? (
        <div className="d-flex align-items-center justify-content-end">
          <SaveBtn
            text="Enregistrer le contrat"
            isSaving={utils.savingContract}
            save={() => items_actions.saveContract(selectedContract)}
          />
        </div>
      ) : null}
    </>
  ) : (
    <></>
  );
};

export default EntityView;
