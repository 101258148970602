import React, { useState } from "react";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import tools from "../../../helpers/tools";
import Axios from "axios";
import { API_URL, APP_NAME } from "../../../config";
import RegisterSuccess from "./RegisterSuccess";

const Register = (props) => {
  const [user, setUser] = useState({
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const [optin, setOptin] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);

  const inputChange = (e) => {
    var elem = JSON.parse(JSON.stringify(user));
    var { name, value, type } = e.target;
    elem[name] = value;
    setUser(elem);
  };

  const register = (e) => {
    e.preventDefault();
    if (userCreated) return false;
    if (!optin) {
      setErrors({ optin: "Veuillez accepter les CGU" });
      return false;
    }

    setIsLoading(true);
    Axios.post(API_URL + "customers", user)
      .then((res) => {
        setUserCreated(true);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return userCreated ? (
    <RegisterSuccess />
  ) : (
    <form
      onSubmit={(e) => register(e)}
      className="d-flex align-items-center justify-content-center flex-column mx-auto mt-5 login-form"
    >
      <div className=" text-center">
        <div className="mb-5">
          <img src="/images/logo.svg" />
        </div>
        <h1 className="my-5  text-secondary main-title">
          Je m'inscrit sur {APP_NAME}
          <hr className="w-50 mt-4" />
        </h1>
      </div>
      <Control
        className="w-75"
        label="Civilité"
        type="select"
        name="gender"
        value={user.gender}
        change={inputChange}
        datas={[
          { id: 1, name: "Monsieur" },
          { id: 2, name: "Madame" },
        ]}
        error={errors}
        setError={setErrors}
        required={true}
      />
      <Control
        className="w-75"
        label="Nom"
        type="text"
        name="lastname"
        value={user.lastname}
        change={inputChange}
        error={errors}
        setError={setErrors}
        required={true}
      />
      <Control
        className="w-75"
        label="Prénom"
        type="text"
        name="firstname"
        value={user.firstname}
        change={inputChange}
        error={errors}
        setError={setErrors}
        required={true}
      />
      <Control
        className="w-75"
        label="Email"
        type="email"
        name="email"
        value={user.email}
        change={inputChange}
        error={errors}
        setError={setErrors}
        required={true}
      />
      <Control
        className="w-75"
        label="Mot de passe"
        type="password"
        name="password"
        value={user.password}
        change={inputChange}
        error={errors}
        setError={setErrors}
        required={true}
      />
      <Control
        className="w-75"
        label="Retapez le mot de passe"
        type="password"
        name="passwordConfirm"
        value={user.passwordConfirm}
        change={inputChange}
        isValid={
          user.password == user.passwordConfirm && user.password.length >= 6
        }
        error={errors}
        setError={setErrors}
        required={true}
      />
      <Control
        type="checkbox"
        label="J'ai lu et j'accepte les conditions d'utilisation"
        name="optin"
        className="w-75"
        checked={optin}
        change={(e) => setOptin(e.target.checked)}
        error={errors}
      />
      <div className="w-75 mx-auto d-flex justify-content-end">
        {
          <SaveBtn
            className="animated zoomIn"
            isSaving={isLoading}
            text="S'inscrire"
            type="primary"
            typeBtn="submit"
          />
        }
      </div>
      <div className="w-75 mx-auto d-flex justify-content-start mt-2">
        <button
          onClick={() => props.setRegister(false)}
          style={{ fontSize: 12 }}
          type="button"
          className="btn btn-link text-secondary p-0"
        >
          <i className="fa fa-chevron-left mr-2" />
          Revenir à la connexion
        </button>
      </div>
    </form>
  );
};

export default Register;
