import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import useStore from "../../../../context/useStore";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";
import Loader from "../../../common/Loader";
import DayBlock from "./DayBlock";

const ClientMenuPrint = (props) => {
  console.log("props", props);
  const [state] = useStore();
  const { DAYS, CONSUME_TIMES } = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const { date, menurefId, clientId, siteId } = props.match.params;
  console.log("props.match.params", props.match.params);
  const [clientDatas, setClientDatas] = useState({});
  const [siteDatas, setSiteDatas] = useState({});
  const [printMoments, setPrintMoments] = useState([3, 5]);

  let blockColorGreen = "rgb(154 179 91 / 90%)";
  let blockColorBlue = "rgb(91 116 179 / 90%)";
  let globalPadding = "0 30px";

  // var datas = [
  //   {
  //     day: 1,
  //     moments: [
  //       {
  //         moment: 3,
  //         dishTypes: [
  //           {
  //             dishType: "Entrée",
  //             technicalSheets: [
  //               {
  //                 name: "Carottes rapées",
  //                 alternatives: ["Macédoine", "Merdenboite"],
  //               },
  //               {
  //                 name: "Jus de fruis",
  //               },
  //               {
  //                 name: "Crottedenez",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Féculent",
  //             technicalSheets: [
  //               {
  //                 name: "Riz",
  //                 alternatives: ["Pâtes"],
  //               },
  //               {
  //                 name: "Semoule",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Dessert",
  //             technicalSheets: [{ name: "Gateau" }, { name: "Spéculoos" }],
  //           },
  //         ],
  //       },
  //       {
  //         moment: 5,
  //         dishTypes: [
  //           {
  //             dishType: "Entrée",
  //             technicalSheets: [
  //               {
  //                 name: "Carottes rapées",
  //                 alternatives: ["Macédoine", "Merdenboite macérée"],
  //               },
  //               {
  //                 name: "Jus de fruis",
  //               },
  //               {
  //                 name: "Crottedenez",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Féculent",
  //             technicalSheets: [
  //               {
  //                 name: "Riz",
  //                 alternatives: ["Pâtes"],
  //               },
  //               {
  //                 name: "Semoule",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Dessert",
  //             technicalSheets: [{ name: "Gateau" }, { name: "Spéculoos" }],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     day: 2,
  //     moments: [
  //       {
  //         moment: 3,
  //         dishTypes: [
  //           {
  //             dishType: "Entrée",
  //             technicalSheets: [
  //               {
  //                 name: "Carottes rapées",
  //                 alternatives: ["Macédoine", "Merdenboite"],
  //               },
  //               {
  //                 name: "Jus de fruis",
  //               },
  //               {
  //                 name: "Crottedenez",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Féculent",
  //             technicalSheets: [
  //               {
  //                 name: "Riz",
  //                 alternatives: ["Pâtes"],
  //               },
  //               {
  //                 name: "Semoule",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Dessert",
  //             technicalSheets: [{ name: "Gateau" }, { name: "Spéculoos" }],
  //           },
  //         ],
  //       },
  //       {
  //         moment: 5,
  //         dishTypes: [
  //           {
  //             dishType: "Entrée",
  //             technicalSheets: [
  //               {
  //                 name: "Carottes rapées",
  //                 alternatives: ["Macédoine", "Merdenboite macérée"],
  //               },
  //               {
  //                 name: "Jus de fruis",
  //               },
  //               {
  //                 name: "Crottedenez",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Féculent",
  //             technicalSheets: [
  //               {
  //                 name: "Riz",
  //                 alternatives: ["Pâtes"],
  //               },
  //               {
  //                 name: "Semoule",
  //               },
  //             ],
  //           },
  //           {
  //             dishType: "Dessert",
  //             technicalSheets: [{ name: "Gateau" }, { name: "Spéculoos" }],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  const [datas, setDatas] = useState([]);
  const isPrintReady = datas.length > 0;

  useEffect(() => {
    getClientMenuPrint();
  }, []);

  const getClientMenuPrint = () => {
    setIsLoading(true);
    dataService.get("sites/mines", (data) => {
      setSiteDatas(data.find((d) => d.id == siteId));
      dataService.get(
        `menurefs/${menurefId}/sites/${siteId}/menu`,
        setDatas,
        (err) => {},
        () => setIsLoading(false)
      );
    });
  };

  const printMenu = () => {
    tools.print("landscape");
  };

  if (!isPrintReady) return <Loader />;

  const days = [];
  datas.forEach((day) => {
    if (siteDatas.activeDays && siteDatas.activeDays.includes(day.day)) {
      if (!days.find((d) => d == day.day)) {
        if (day.moments.find((m) => printMoments.find((pm) => pm == m.moment))) {
          days.push(day.day);
        }
      }
    }
  });
  const moments = [];
  datas.forEach((day) => {
    day.moments.forEach((moment) => {
      if (!moments.find((m) => m == moment.moment) && printMoments.find((m) => m == moment.moment))
        moments.push(moment.moment);
    });
  });
  moments.sort((a, b) => (a > b ? 1 : -1));

  console.log("printMoments", printMoments);

  return (
    <>
      <div
        className="noprint border rounded bg-white p-3"
        style={{
          maxWidth: 400,
          margin: "40px auto",
        }}
      >
        <Control
          label="Sélectionner les moments à afficher"
          name="printMoments"
          type="btnList"
          btnInline
          datas={[
            {
              id: "3,5",
              name: "Déjeuner et Diner",
            },
            {
              id: "2,4",
              name: "Goûter et Collation",
            },
          ]}
          value={printMoments.join(",")}
          change={(e) => setPrintMoments(e.target.value.split(","))}
          dataIndex="id"
          dataLabel="name"
        />
        <button className="btn btn-secondary w-100" onClick={printMenu}>
          <i className="fa fa-print mr-2" />
          IMPRIMER
        </button>
      </div>
      <div style={{ background: "white" }}>
        <div
          style={{
            position: "relative",
            height: 740,
            zIndex: "2",
          }}
        >
          <h1 className="text-center h4 p-0 pt-3">
            Menus Qualicarry du {DateTime.fromISO(date).toFormat("dd/MM/yyyy")} au{" "}
            {DateTime.fromISO(date).plus({ days: 6 }).toFormat("dd/MM/yyyy")}
          </h1>
          <div>
            <div
              className=""
              style={{
                position: "relative d-flex justify-content-around flex-column h-100",
                margin: 0,
                padding: globalPadding,
                //background: "white",
                borderRadius: 40,
                zIndex: "2",
              }}
            >
              <div className="row" style={{ position: "relative", zIndex: "3" }}>
                <div
                  className="col px-1"
                  style={{
                    transform: "rotate(-90deg)",
                    height: 40,
                    maxWidth: 40,
                  }}
                ></div>
                {days.map((day) => (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "700",
                      color: "black",
                      marginBottom: 10,
                      fontSize: 17,
                      backgroundImage: "url(/images/day-bg.png)",
                      backgroundRepeat: "no-repeat",
                      height: 120,
                      backgroundSize: "100% 100%",
                      paddingTop: 20,
                    }}
                    className={`col px-1 d-center`}
                  >
                    {tools.findIn(DAYS, day, "id").name}
                  </div>
                ))}
              </div>
              {moments.map((moment) => {
                return (
                  <div className="row position-relative" style={{ zIndex: "3" }}>
                    <div
                      className="col px-1 d-center justify-content-start text-secondary"
                      style={{
                        transform: "rotate(-90deg)",
                        maxHeight: "100%",
                        maxWidth: 40,
                        fontWeight: "800",
                      }}
                    >
                      {tools.findIn(CONSUME_TIMES, moment, "id").name}
                    </div>
                    {days.map((day) => {
                      return (
                        <div className={`col px-1`}>
                          <DayBlock
                            day={day}
                            moment={moment}
                            colorGreen={blockColorGreen}
                            colorBlue={blockColorBlue}
                            left={0}
                            datas={datas}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            style={{
              backgroundImage: " url(/logo.png)",
              width: 268,
              height: 91,
              position: "absolute",
              bottom: 0,
              right: 20,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            style={{
              backgroundImage: " url(/images/menu-illu-4.png)",
              width: 268,
              height: "239px",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            style={{
              backgroundImage: "url(/images/menu-illu-4.png)",
              width: "425px",
              height: "212px",
              position: "absolute",
              top: "106px",
              right: -107,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              transform: "rotate(90deg)",
            }}
          ></div>
          <div
            style={{
              backgroundImage: "url(/images/menu-illu-1.png)",

              width: "197px",
              height: "181px",
              position: "absolute",
              bottom: "0",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            style={{
              backgroundImage: " url(/images/menu-illu-2.png)",
              width: "231px",
              height: "221px",
              position: "absolute",
              bottom: "0px",
              left: "150px",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            style={{
              backgroundImage: " url(/images/menu-illu-3.png)",
              width: "231px",
              height: "221px",
              position: "absolute",
              top: -26,
              right: 0,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ClientMenuPrint;
