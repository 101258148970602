import React from "react";

const CheckboxInput = ({
  inputClassName = "",
  disabled = false,
  name,
  k = -1,
  checked = false,
  labelClassname = "",
  change = () => {},
  required = false,
  label = false,
  error = {},
}) => {
  const valueChange = (e) => {
    var filteredValue = e.target.value;
    console.log(filteredValue);
    let changeEvent = {
      target: {
        name: name,
        value: filteredValue,
        checked: e.target.checked,
        type: "checkbox",
        k: k,
      },
    };

    change(changeEvent);
  };

  return (
    <div
      className={"custom-control custom-switch d-flex align-items-center"}
      style={{ height: 54 }}
    >
      <input
        type="checkbox"
        className={"custom-control-input " + inputClassName}
        disabled={disabled ? "disabled" : false}
        name={name}
        id={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
        onChange={valueChange}
        checked={checked ? "checked" : false}
      />
      <label
        className={
          "custom-control-label d-flex align-items-center border-0 " +
          labelClassname
        }
        style={{ cursor: "pointer", minHeight: "0", padding: 0 }}
        htmlFor={
          k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name
        }
      >
        {label && (
          <span>
            {label}
            {required && <span className="text-danger ml-1">*</span>}
          </span>
        )}
      </label>
      {error[name] && (
        <small
          className={
            "form-text text-danger " + (error[name] ? "animated flash" : "")
          }
        >
          {error[name]}
        </small>
      )}
    </div>
  );
};

export default CheckboxInput;
