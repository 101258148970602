const Validation = {
  validate: (type, value, required) => {
    let isValid = true;
    var sval = parseInt(value) === 0 ? 0 : value ? value : [];
    let hasValue = Validation.required(sval);

    if (hasValue) {
      switch (type) {
        case "text":
          isValid = true;
          break;
        case "email":
          isValid = Validation.email(value);
          break;

        case "number":
          isValid = Validation.number(value);
          break;
        case "tel":
          isValid = Validation.tel(value);
          break;

        default:
          isValid = true;
      }
    }
    return isValid && (required ? hasValue : true);
  },
  required: (value) => {
    switch (typeof value) {
      case "array":
        return value.length > 0;
        break;
      case "object":
        return value.length > 0;
        break;

      case "date":
        return value && Object.keys(value).length > 0;
        break;

      case "number":
        return !isNaN(value);
        break;

      default:
        return value && (value + "").length > 0;
    }
  },
  email: (value) => {
    return true;
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
  },
  tel: (value) => {
    return /^\d{10}$/.test(value);
  },
  number: (value) => {
    return !isNaN(parseFloat(value));
  },
};

export default Validation;
