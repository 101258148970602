import React, { useEffect, useState } from "react";
import tools from "../../../../../helpers/tools";
import useStoreState from "../../Context/Store/useStoreState";
import CommentBlock from "./CommentBlock";
import ContractBlock from "./ContractBlock/ContractBlock";
import DayBlock from "./DayBlock";
import MomentBlock from "./MomentBlock";
import SaveOrderButton from "./SaveOrderButton";

const OrderList = () => {
  const { state, gState, items, items_actions } = useStoreState();
  const { sites, refSlotOrders, lockedDays } = items;
  const { selectedSite, selectedDate } = items.utils;

  const DAYS = gState.constants.items.DAYS.filter((day) =>
    sites.find((s) => s.id == selectedSite).activeDays.includes(day.id)
  );

  const MOMENTS = gState.constants.items.CONSUME_TIMES.filter((mm) =>
    refSlotOrders.find((r) => r.moment == mm.id)
  );
  const [showMoments, setShowMoments] = useState(MOMENTS.map((m) => m.id));

  useEffect(() => {
    setShowMoments(MOMENTS.map((m) => m.id));
  }, [refSlotOrders]);

  var isSaveDisabled = false;
  if (lockedDays) {
    var nbLocked = Object.keys(lockedDays).filter((d) => {
      var val = lockedDays[d];
      console.log(
        d,
        DAYS.find((da) => da.id == d)
      );
      return val < 3 && DAYS.find((da) => da.id == d);
    }).length;
    isSaveDisabled = nbLocked == DAYS.length;
  }

  return (
    <div className="d-flex flex-column flex-wrap justify-content-around  pb-5">
      <div className="d-flex justify-content-end w-100">
        <button
          className="btn btn-grey"
          onClick={() => {
            setShowMoments(showMoments.length ? [] : MOMENTS.map((m) => m.id));
          }}
        >
          <i
            className={`mr-2 fa fa-${
              showMoments.length ? "compress-alt" : "expand-alt"
            }`}
          />
          {showMoments.length ? "Tout replier" : "Tout déplier"}
        </button>
      </div>
      {MOMENTS.map((moment, mk) => (
        <MomentBlock
          moment={moment}
          key={`mblock${moment.name}`}
          showMoments={showMoments}
          setShowMoments={setShowMoments}
        >
          <div
            className="d-flex justify-content-arround align-items-start flex-row w-100"
            style={{
              maxHeight: showMoments.includes(moment.id) ? 6000 : 0,
              transition: "max-height 200ms",
              overflow: "hidden",
            }}
          >
            {DAYS.map((day, dk) => (
              <DayBlock
                lockedDays={lockedDays}
                day={day}
                moment={moment}
                key={`mblock${day.name}`}
                nbDays={DAYS.length}
                beginAt={selectedDate}
              >
                <ContractBlock day={day} moment={moment} />
              </DayBlock>
            ))}
          </div>
        </MomentBlock>
      ))}
      {state.isLoading ? null : (
        <>
          <SaveOrderButton isDisabled={isSaveDisabled} />
          <CommentBlock />
        </>
      )}
    </div>
  );
};

export default OrderList;
