import React from "react";

const ConsumptionProfileItem = ({ cs, ...props }) => {
  return (
    <div
      className={`text-white d-flex justify-content-start w-100 flex-row h-100`}
    >
      {/* <div
        className="d-center p-2 text-center"
        style={{
          background: cs.color,
          width: "20%",
          fontSize: 12,
          writingMode: "tb-rl",
          transform: "rotate(180deg)",
        }}
      >
        {cs.name}
      </div> */}
      {props.children}
    </div>
  );
};

export default ConsumptionProfileItem;
