import React, { useEffect, useState } from "react";
import { CirclePicker } from "react-color";

const ColorPicker = ({ color, setColor, position = { bottom: 25 } }) => {
  const [isOpen, setIsOpen] = useState(false);

  const colors = [
    //marron
    "#593F36",
    "#66483D",
    "#8C6354",
    "#996C5C",
    "#A37666",

    //mauve
    "#4F2C8C",
    "#58319B",
    "#6136AB",
    "#673AB7",
    "#7445C4",

    //bleu foncé
    "#334A71",
    "#39547F",
    "#3F5D8D",
    "#46669B",
    "#496CA3",

    //bleu clair
    "#08599B",
    "#0964AE",
    "#0A6FC2",
    "#0B7AD5",
    "#0C85E9",

    //vert foncé
    "#1A6158",
    "#1E7167",
    "#228176",
    "#279185",
    "#2A9D8F",

    //vert clair
    "#2C632D",
    "#327134",
    "#38803A",
    "#3E8E41",
    "#449C47",

    //vert caki
    "#5E5731",
    "#6B6338",
    "#78703F",
    "#867C46",
    "#93894D",

    //rouge
    "#9E0B00",
    "#B80C00",
    "#CC0E00",
    "#E00F00",
    "#F51000",

    //orange
    "#B74D01",
    "#CB5501",
    "#DF5E01",
    "#EF6601",
    "#FE700B",

    //rose
    "#A71143",
    "#BA124A",
    "#CC1452",
    "#DF1659",
    "#E91E63",

    //gris
    "#5C5C5C",
    "#666666",
    "#707070",
    "#7A7A7A",
    "#858585",

    // "#AE1409",
    // "#C2160A",
    // "#D5190B",
    // "#E91B0C",
    // "#F32516",

    //marrons
    // "#6b483b",
    // "#795548",
    // "#8c6558",
    // "#a27464",
    // "#c18874",

    // "#bd9c38",
    // "#ff9800",
    // "#ff5722",
    // "#f44336",
    // "#e91e63",
    // "#9c27b0",
    // "#673ab7",
    // "#3f51b5",
    // "#2196f3",
    // "#03a9f4",
    // "#00bcd4",
    // "#009688",
    // "#4caf50",
    // "#8bc34a",
    // "#a6b50f",
    // "#585858",

    // "#607d8b",
  ];

  useEffect(() => {
    setIsOpen(false);
  }, [color]);

  return (
    <div className="position-relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          background: color,
          borderRadius: "100%",
          width: 25,
          height: 25,
          border: "1px solid white",
          boxShadow: "0 2px 2px 0px black",
        }}
      ></button>
      {isOpen && (
        <div
          className="position-absolute bg-white p-3 border animated fadeIn faster"
          style={{
            ...position,
            zIndex: "99",
            height: 212,
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <CirclePicker
            color={color}
            onChange={(color) => setColor(color.hex)}
            colors={colors}
            circleSpacing={18}
            circleSize={32}
          />
        </div>
      )}
      {/* {colors.map((c) => (
        <div
          style={{
            background: c,
            padding: 5,
            color: "white",
          }}
        >
          TEST
        </div>
      ))} */}
    </div>
  );
};

export default ColorPicker;
