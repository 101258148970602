import { DateTime } from "luxon";
import React from "react";
import tools from "../../../helpers/tools";
import useSites from "../../common/Hooks/useSites";

const OrderAlerts = () => {
  const { sites } = useSites(false);

  const lateOrderSites = sites
    .map((site) => {
      if (site.lastOrderAt) {
        let lateDays = DateTime.local()
          .diff(DateTime.fromISO(site.lastOrderAt), "days")
          .toObject().days;
        return { ...site, lateDays };
      }
      return site;
    })
    .filter((site) => site.lateDays && site.lateDays >= 6);

  return (
    <div>
      {lateOrderSites.map((site) => (
        <div className="alert alert-danger d-flex flex-column rounded mb-2">
          <div className="">
            <i className="fa fa-warning mr-2" />
            <strong>Rappel de commande : Site {site.name}</strong>
          </div>
          <p>
            Votre dernière commande pour le site {site.name} remonte à {parseInt(site.lateDays)}{" "}
            jours. Pensez à enregistrer votre commande dans les meilleurs délais.
          </p>
        </div>
      ))}
    </div>
  );
};

export default OrderAlerts;
