import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../helpers/tools";
import useUser from "../../common/Hooks/useUser";

const UserInfos = () => {
  const { user } = useUser();

  return (
    <div className="p-3 bg-white rounded shadow-sm" style={{ maxWidth: 350 }}>
      <div>
        <i className="fa fa-user mr-2 text-dark" />
        {tools.getFullName(user)}
      </div>
      <div>
        <i className="fa fa-phone mr-2 text-dark" />
        {tools.formatPhone(user.phone)}
      </div>
      <div>
        <i className="fa fa-envelope mr-2 text-dark" />

        {user.email}
      </div>
      <Link
        to="/profile"
        className="btn btn-link w-100 p-0 text-left mt-2"
        style={{ minHeight: 20, height: "auto" }}
      >
        <i className="fa fa-pen mr-1" />
        Modifier mes informations
      </Link>
    </div>
  );
};

export default UserInfos;
