import React from "react";
import { modalActions } from "../../../context/actions/modalActions";
import useStore from "../../../context/useStore";

const CustomModal = () => {
  const [state, dispatch] = useStore();
  const actions = modalActions(state, dispatch);
  const modalProps = state.modal;

  return (
    <div
      onClick={() => {
        actions.updateModal({ isOpen: false });
      }}
      className="position-fixed w-100 h-100 align-items-center justify-content-center"
      style={{
        display: modalProps.isOpen ? "flex" : "none",
        top: 0,
        left: 0,
        background: "rgb(0 0 0 / 54%)",
        zIndex: "9999",
        overflowY: "auto",
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={
          "bg-white position-fixed p-5 " +
          (modalProps.isOpen ? "animated zoomIn faster" : "")
        }
        style={{
          zIndex: "9999",
          top: 0,
          width: "96%",
          height: "98%",
          maxWidth: "400",
          borderRadius: 5,
          overflow: "scroll",
        }}
      >
        {modalProps.content}

        <div
          className="d-center"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: 10,
            borderRadius: "100%",
          }}
        >
          {modalProps.actions}
          <button
            onClick={() =>
              actions.updateModal({ isOpen: false, content: null })
            }
            className="btn btn-default w-100"
          >
            <i className="fa fa-times" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
