import { Provider } from "jotai";
import { DateTime } from "luxon";
import React from "react";
import useSites from "../Hooks/useSites";
import SiteCPForm from "./SiteCPForm";

const ClosingPeriods = () => {
  const { sites } = useSites(false);

  return (
    <div className="row my-4">
      <div className="col-12">
        <h5 className="py-0 text-primary">Périodes de fermeture des sites programmées</h5>
        {sites.map((site) => (
          <div className="custom-card my-2">
            <strong>{site.name}</strong>
            <Provider>
              <SiteCPForm
                siteData={{
                  ...site,
                  closingPeriods: site.closingPeriods.filter(
                    (c) => c.end > DateTime.local().toISO()
                  ),
                }}
              />
            </Provider>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClosingPeriods;
