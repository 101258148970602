import React from "react";

const Progress = ({ progress }) => {
  return (
    <progress value={progress} max="100" className="w-100">
      <div className="progress-bar">
        <span style={{ width: progress + "%" }}>{progress}%</span>
      </div>
    </progress>
  );
};

export default Progress;
