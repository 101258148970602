const IS_LOADING = "IS_LOADING";
const IS_LOADING_CLIENTS = "IS_LOADING_CLIENTS";
const IS_LOADING_SITES = "IS_LOADING_SITES";
const IS_LOADING_ORDER = "IS_LOADING_ORDER";
const IS_INIT = "IS_INIT";
const INIT_ITEMS = "INIT_ITEMS";
const UPDATE_ITEM = "UPDATE_ITEM";
const SET_ERRORS = "SET_ERRORS";
const UPDATE_SELECTED_CLIENT = "UPDATE_SELECTED_CLIENT";
const UPDATE_SELECTED_SITE = "UPDATE_SELECTED_SITE";
const UPDATE_SELECTED_DATE = "UPDATE_SELECTED_DATE";
const LOAD_SITES = "LOAD_SITES";
const LOAD_ORDER = "LOAD_ORDER";
const UPDATE_SLOT_ORDERS = "UPDATE_SLOT_ORDERS";

const initialState = {
  isLoading: false,
  isLoadingClients: false,
  isLoadingSites: false,
  isLoadingOrder: false,
  items: {},
  actionUrls: {},
  errors: {},
  isInit: false,
};

const initItems = (payload, state) => {
  const { items, actionUrls } = payload;
  return { ...state, items, actionUrls, isLoading: false, isInit: true };
};

const updateItem = ({ item, value }, state) => {
  return { ...state, items: { ...state.items, [item]: value } };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    case IS_LOADING_CLIENTS:
      return { ...state, isLoadingClients: action.payload };
    case IS_LOADING_SITES:
      return { ...state, isLoadingSites: action.payload };
    case IS_LOADING_ORDER:
      return { ...state, isLoadingOrder: action.payload };
    case INIT_ITEMS:
      return initItems(action.payload, state);
    case IS_INIT:
      return { ...state, isInit: action.payload };

    case UPDATE_ITEM:
      return updateItem(action.payload, state);

    case SET_ERRORS:
      return { ...state, errors: action.payload };
    case UPDATE_SELECTED_CLIENT:
      return {
        ...state,
        items: {
          ...state.items,
          utils: {
            ...state.items.utils,
            selectedClient: action.payload,
          },
        },
      };
    case UPDATE_SELECTED_SITE:
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, selectedSite: action.payload },
        },
      };
    case UPDATE_SELECTED_DATE:
      return {
        ...state,
        items: {
          ...state.items,
          utils: { ...state.items.utils, selectedDate: action.payload },
        },
      };
    case LOAD_SITES:
      return {
        ...state,
        isLoadingSites: false,
        items: {
          ...state.items,
          sites: action.payload,
        },
      };
    case LOAD_ORDER:
      console.log("ACT", action.payload);
      return {
        ...state,
        isLoadingOrder: false,
        items: {
          ...state.items,
          lockedDays: action.payload.lockedDays || state.items.lockedDays,
          order: action.payload.order,
          refSlotOrders: action.payload.order.slotOrders,
        },
      };
    case UPDATE_SLOT_ORDERS:
      return {
        ...state,
        items: {
          ...state.items,
          order: { ...state.items.order, slotOrders: action.payload },
        },
      };

    default:
      return state;
  }
};
