import React, { useState } from "react";
import SiteForm from "../../../Sites/SiteDetail/SiteForm";
import useStoreState from "../useStoreState";
import ContractsContainer from "./ContractsContainer";

const SitesContainer = () => {
  const { state, items, items_actions } = useStoreState();
  const { client, sites, contracts, productionSites } = { ...items };
  const [showSiteDetail, setShowSiteDetail] = useState(false);

  return (
    <div className="row mt-4">
      {sites.map((s, sk) => {
        return (
          <div className="col-12 mb-3" key={`site${s.id}`}>
            <div className="bg-white shadow-sm p-3 rounded">
              <h4 className="pt-0">Site {s.name}</h4>
              <button
                className="btn btn-default d-center btn-sm "
                onClick={(e) => {
                  setShowSiteDetail((i) => {
                    return i == s.id ? false : s.id;
                  });
                  items_actions.setEntitySelected("site", s.id);
                }}
              >
                {showSiteDetail == s.id ? "Masquer" : "Afficher"} les
                informations du site
              </button>
              {/* <button
                className="btn btn-sm"
                onClick={() => items_actions.removeSite(s.id)}
                style={{
                  position: "absolute",
                  top: 2,
                  right: 16,
                }}
              >
                <i className="fa fa-times text-danger" />
              </button> */}
              <strong className="d-block w-100 mt-2">Contrats</strong>
              <ContractsContainer site={s} />
              {showSiteDetail == s.id && (
                <SiteForm
                  site={s}
                  setSite={() => {}}
                  productionSites={productionSites}
                />
              )}
            </div>
          </div>
        );
      })}
      {/* <div className="col-12 d-center my-3">
        <button
          className="btn btn-default d-flex flex-fill mx-1 btn-sm my-1 d-center"
          onClick={(e) => items_actions.setEntitySelected("site", "")}
        >
          <i className="fa fa-plus mr-2" />
          Ajouter un site
        </button>
        <button
          className="btn btn-default btn-sm my-1 d-flex flex-fill mx-1 d-center"
          onClick={(e) => items_actions.setEntitySelected("contract", "")}
        >
          <i className="fa fa-plus mr-2" />
          Ajouter un contrat
        </button>
      </div> */}
    </div>
  );
};

export default SitesContainer;
