import React from "react";
import Control from "../../../../common/Controls/Control";

const PeriodSelector = ({ beginAt, endAt, setBeginAt, setEndAt }) => {
  return (
    <>
      <div className="col-4">
        <Control
          label="Entre le"
          type="date"
          name="beginAt"
          value={beginAt}
          change={(e) => setBeginAt(e.target.value)}
        />
      </div>
      <div className="col-4">
        <Control
          label="Et le"
          type="date"
          name="endAt"
          value={endAt}
          change={(e) => setEndAt(e.target.value)}
        />
      </div>
    </>
  );
};

export default PeriodSelector;
