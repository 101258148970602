import { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";

const useUser = (fetchOnLoad = true) => {
  const [user, setUser] = useState([]);

  useEffect(() => {
    if (fetchOnLoad) {
      getUser();
    }
  }, []);

  const getUser = () => {
    dataService.get(`customers/me`, setUser);
  };

  return {
    user,
    setUser,
    getUser,
  };
};

export default useUser;
