import React, { useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import dataService from "../../../helpers/dataService";
import ClosingPeriods from "../../common/ClosingPeriods/ClosingPeriods";
import Page from "../../common/layout/Page";
import CtaBox from "./CtaBox/CtaBox";
import OrderAlerts from "./OrderAlerts";
import Stats from "./Stats/Stats";
import UserInfos from "./UserInfos";

const Dashboard = () => {
  const [state, dispatch] = useStore();
  const { user } = state.auth;
  const [client, setClient] = useState({});

  useEffect(() => {
    getClient();
  }, []);

  const getClient = () => {
    dataService.get(`clients/mine`, setClient);
  };

  return (
    <Page
      title={
        <span>
          Bienvenue <span style={{ textTransform: "capitalize" }}>{user.firstname}</span>
        </span>
      }
    >
      <OrderAlerts />
      <div className="row">
        <div className="col-12 mb-3">
          <UserInfos />
        </div>
        <div className="col-12 mb-3">
          <p className="text-primary font-weight-bold">Client : {client.name || ""}</p>
          <p className="text-primary">
            <i>Que souhaitez-vous faire ?</i>
          </p>
        </div>
        <div className="col-12 col-md-3 mb-3" data-priv="order">
          <CtaBox
            icon="shopping-basket"
            title={<>Passer une commande</>}
            url="/order"
            buttonText="Accéder"
            buttonIconRight="fa-arrow-right"
          />
        </div>
        <div className="col-12 col-md-3 mb-3" data-priv="manage_account">
          <CtaBox
            icon="users-cog"
            title={<>Gérer mon compte client</>}
            url="/client"
            buttonText="Accéder"
            buttonIconRight="fa-arrow-right"
          />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <CtaBox
            icon="file-alt"
            title={<>Voir le récapitulatif d'activité</>}
            url="/recap-activite"
            buttonText="Accéder"
            buttonIconRight="fa-arrow-right"
          />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <CtaBox
            icon="fish"
            title={<>Consulter les fiches techniques</>}
            url="/fiches-techniques"
            buttonText="Accéder"
            buttonIconRight="fa-arrow-right"
          />
        </div>
        <div className="col-12 col-md-3 mb-3">
          <CtaBox
            icon="key"
            title={<>Changer mon mot de passe</>}
            url="/profile"
            buttonText="Accéder"
            buttonIconRight="fa-arrow-right"
          />
        </div>
      </div>

      <ClosingPeriods />

      <h5 className="py-0 text-primary">Statistiques</h5>
      <Stats />
    </Page>
  );
};

export default Dashboard;
